import { ChannelType } from 'src/shared/enums/platform.enum';
import { IRegion } from 'src/shared/models';

export const PIN_BR: IRegion[] = [
  {
    id: 'a0C5800000FiELVEA3',
    name: 'Acre',
    code: 'AC',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiELrEAN',
    name: 'Alagoas',
    code: 'AL',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEMEEA3',
    name: 'Amapá',
    code: 'AP',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEMGEA3',
    name: 'Amazonas',
    code: 'AM',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEOKEA3',
    name: 'Bahía',
    code: 'BA',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEQfEAN',
    name: 'Ceará',
    code: 'CE',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiESfEAN',
    name: 'Distrito Federal',
    code: 'DF',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiETUEA3',
    name: 'Espírito Santo',
    code: 'ES',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEUeEAN',
    name: 'Goiás',
    code: 'GO',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEaSEAV',
    name: 'Maranhão',
    code: 'MA',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEafEAF',
    name: 'Mato Grosso',
    code: 'MT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEagEAF',
    name: 'Mato Grosso do Sul',
    code: 'MS',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEbAEAV',
    name: 'Minas Gerais',
    code: 'MG',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEdkEAF',
    name: 'Pará',
    code: 'PA',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEdlEAF',
    name: 'Paraíba',
    code: 'PB',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEdmEAF',
    name: 'Paraná',
    code: 'PR',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEe5EAF',
    name: 'Pernambuco',
    code: 'PE',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEeNEAV',
    name: 'Piauí',
    code: 'PI',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEg2EAF',
    name: 'Rio de Janeiro',
    code: 'RJ',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEg4EAF',
    name: 'Rio Grande do Norte',
    code: 'RN',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEg5EAF',
    name: 'Rio Grande do Sul',
    code: 'RS',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEgHEAV',
    name: 'Rondônia',
    code: 'RO',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEgIEAV',
    name: 'Roraima',
    code: 'RR',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEgyEAF',
    name: 'Santa Catarina',
    code: 'SC',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEh9EAF',
    name: 'São Paulo',
    code: 'SP',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEhPEAV',
    name: 'Sergipe',
    code: 'SE',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEjkEAF',
    name: 'Tocantins',
    code: 'TO',
    channel: ChannelType.NATIONAL,
  },
];
