import {
  YuBadgeNumeric,
  YuBanner,
  YuButton,
  YuFlex,
  YuIcon,
  YuLink,
  YuParagraph,
  YuQuantitySelector,
  YuRadioControl,
  YuSpacer,
  YuTitle,
} from '@isdin/yuma-react-web-pin';
import { Button, Col, Radio, Row } from 'antd';
import { config } from 'config/config';
import routePaths from 'config/routes';
import React, { FC, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { loadingSpinner__small } from 'src/components';
import Link from 'src/components/Link';
import { CheckoutPhoneInput } from 'src/components/redeemPoints/checkout/checkout.utils';
import MyBagProduct from 'src/components/redeemPoints/MyBagProduct';
import { PIN_PlatformID_Enum, shouldHidePostalCode } from 'src/shared/enums';
import {
  IFilter,
  IFilterItem,
  IQueryRedeenPointsPrductsFilter,
  IRedeemPointsContent,
  IRedeemPointsFooter,
  IRedeemPointsHeader,
  IRedeemPointsProduct,
  IRedeemPointsProductBase,
  IRegion,
  ISalesforceAddress,
  ISalesforceCenter,
  IShippingAddress,
  IUser,
} from 'src/shared/models';
import { BannerMaximumWarningExceeded } from '../src/components/orderLimits/bannerMaximumWarningExceeded';
import { useTotalItemsToRemoveBag } from '../src/hooks/useTotalItemsToRemoveBag';
import { IRootReducers } from '../src/reducers';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { privateRoute } from '../services/privateRoute';
import {
  isDNIRequired,
  isESPlatform,
  isUSAPlatform,
} from 'utils/platformUtils';
import _ from 'lodash';
import { addressExceedLimitForCurrentPlatform } from '../src/validations/addressExceedLimitForCurrentPlatform';
import { customFormatMessage } from './intlUtils';
import { NextRouter } from 'next/router';

enum MyBagFooterErrors {
  NOT_ENOUGH_POINTS = 'page.redeem-points.checkout.not-enough-points',
  NO_PHONE = 'page.redeem-points.checkout.no-phone-added',
}

type _IRedeemPointsFooter = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  IRedeemPointsFooter;

export const _MyBagMenu__Footer: FC<_IRedeemPointsFooter> = ({
  isDrawer = false,
  cart,
  hasBalanceSheet = false,
  balanceConfig,
  canRedeem = true,
  onClickButton,
  redirectToCheckout = true,
  redirectToMyBagOnCTA = false,
  buttonText,
  showButtonLoader = false,
  userPhone,
  showPhoneInput = false,
  showPhoneError = false,
  setUserPhone,
  forceDisabledButton = false,
  orderRestrictions,
  showEditAddressWarning = false,
  onEditAddress = _.noop,
  user,
}): JSX.Element => {
  const [showBalance, setShowBalance] = useState(false);
  const { formatNumber, formatMessage } = useIntl();
  const totalPoints = cart.cartTotals?.total;

  const userPointsAfterRedeem = balanceConfig?.userPoints - totalPoints;
  if (userPointsAfterRedeem < 0) canRedeem = false;

  const userNotEnoughtPoints =
    !canRedeem && userPointsAfterRedeem < 0 && !redirectToCheckout;
  const error =
    (userNotEnoughtPoints && MyBagFooterErrors.NOT_ENOUGH_POINTS) ||
    (showPhoneError && MyBagFooterErrors.NO_PHONE);
  const toRemove = useTotalItemsToRemoveBag({
    orderRestrictions,
    bagProducts: cart.items,
  });

  const disabledButton =
    showButtonLoader ||
    !canRedeem ||
    !!error ||
    forceDisabledButton ||
    (!redirectToMyBagOnCTA && !isDrawer && !!toRemove) ||
    (user && isDNIRequired && !user?.salesforce?.userDocumentID) ||
    showEditAddressWarning;

  return (
    <Col span={24} className="my-bag-preview__footer">
      <Row
        className={`my-bag-preview__footer-total my-bag-preview__footer_container`}
        justify="space-between"
      >
        {cart.cartTotals?.discountAmount < 0 && cart.items.length && (
          <Col span={24}>
            <p className="my-bag__discount-amount">
              <span>
                <FormattedMessage id="page.redeem-points.checkout.discount-applied" />
              </span>
              <span className="my-bag__discount-amount__number">
                <FormattedMessage
                  id="menu.section.personal.{points}-point"
                  values={{
                    points: formatNumber(cart.cartTotals?.discountAmount || 0),
                  }}
                />
              </span>
            </p>
          </Col>
        )}
        <Col>
          <YuParagraph size="XL" fw="bold">
            <span style={{ textTransform: 'uppercase' }}>
              <FormattedMessage id="page.account.my-points.total" />
            </span>
          </YuParagraph>
        </Col>
        <Col>
          <YuParagraph size="XL" fw="bold">
            <FormattedMessage
              id="menu.section.personal.{points}-point"
              values={{
                points: formatNumber(totalPoints),
              }}
            />
          </YuParagraph>
        </Col>
      </Row>
      {hasBalanceSheet && canRedeem && userPointsAfterRedeem >= 0 && (
        <Row className="redeem__balance-sheet">
          <div className="redeem__balance-header">
            <p>
              <FormattedMessage id="page.redeem-points.checkout.balance-enough-points" />
            </p>
            <p onClick={() => setShowBalance((prev) => !prev)}>
              <FormattedMessage id="page.redeem-points.checkout.check-balance" />
              <i className="icon icon--arrow-dropdown-down"></i>
            </p>
          </div>
          {showBalance && (
            <div className="redeem__balance-data">
              <div>
                <p>
                  <b>
                    <FormattedMessage id="page.redeem-points.checkout.current-balance" />
                  </b>
                </p>
                <p>
                  <FormattedMessage
                    id="menu.section.personal.{points}-point"
                    values={{
                      points: formatNumber(balanceConfig?.userPoints),
                    }}
                  />
                </p>
              </div>
              <div>
                <p>
                  <FormattedMessage id="page.redeem-points.checkout.order-value" />
                </p>
                <p>
                  <FormattedMessage
                    id="menu.section.personal.{points}-point"
                    values={{
                      points: formatNumber(totalPoints),
                    }}
                  />
                </p>
              </div>
              <div>
                <p>
                  <FormattedMessage id="page.redeem-points.checkout.final-user-balance" />
                </p>
                <p>
                  <FormattedMessage
                    id="menu.section.personal.{points}-point"
                    values={{
                      points: formatNumber(userPointsAfterRedeem),
                    }}
                  />
                </p>
              </div>
            </div>
          )}
        </Row>
      )}
      {showPhoneInput && (
        <CheckoutPhoneInput {...{ userPhone, formatMessage, setUserPhone }} />
      )}
      {error && (
        <div className="challengeResourceWrapper__label--error">
          <FormattedMessage id={error} />
        </div>
      )}
      {cart.items.length >= 6 ? (
        <YuFlex
          flexDirection="column"
          style={{ width: '100%' }}
          pb={{ initial: 'M' }}
        >
          <BannerMaximumWarningExceeded />
        </YuFlex>
      ) : null}
      {isDNIRequired && user && !user?.salesforce?.userDocumentID && (
        <>
          <YuBanner status="warning">
            <FormattedMessage id="page.redeem-points.checkout.missing-dni.alert" />
          </YuBanner>
          <YuSpacer size={'S'} />
        </>
      )}

      {showEditAddressWarning ? (
        <>
          <YuBanner status="warning">
            {customFormatMessage('form.address.edit-address-before-buy', {
              editLink: (chunks: string[]) => (
                <YuLink size="M" targetBlank onClick={onEditAddress}>
                  {chunks}
                </YuLink>
              ),
            })}
          </YuBanner>
          <YuSpacer size={'S'} />
        </>
      ) : null}

      <Row className="my-bag-preview__footer_container">
        {redirectToMyBagOnCTA || (canRedeem && redirectToCheckout) ? (
          <Link
            href={
              isDrawer || redirectToMyBagOnCTA
                ? routePaths.PAGES.REDEEM_POINTS.MY_BAG
                : routePaths.PAGES.REDEEM_POINTS.CHECKOUT
            }
          >
            <Button
              onClick={onClickButton}
              disabled={disabledButton}
              className={`btn-primary btn-primary--red ${
                disabledButton ? 'btn-primary--red--disabled' : ''
              }`}
              data-testid="button-bag-table-redeem-points"
            >
              <FormattedMessage
                id={buttonText || 'menu.section.personal.points.redeem-coupon'}
              />
              {showButtonLoader && loadingSpinner__small}
            </Button>
          </Link>
        ) : (
          <Button
            onClick={userPointsAfterRedeem >= 0 && onClickButton}
            disabled={disabledButton}
            className={`btn-primary btn-primary--red ${
              disabledButton ? 'btn-primary--red--disabled' : ''
            }`}
            data-testid="button-bag-redeem-points"
          >
            <FormattedMessage
              id={buttonText || 'menu.section.personal.points.redeem-coupon'}
            />
            {showButtonLoader && loadingSpinner__small}
          </Button>
        )}
      </Row>
    </Col>
  );
};

const mapStateToProps = (state: IRootReducers) => {
  return {
    orderRestrictions: state.auth.user.magento?.orderRestrictions,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({}, dispatch);

export const MyBagMenu__Footer = connect<
  ReturnType<typeof mapStateToProps>,
  ReturnType<typeof mapDispatchToProps>,
  IRedeemPointsFooter
>(
  mapStateToProps,
  mapDispatchToProps
)(
  privateRoute({
    WrappedComponent: _MyBagMenu__Footer,
  })
);

export const MyBagMenu__Content: FC<IRedeemPointsContent> = ({
  bagProducts,
  handleRemoveProduct,
  isCheckoutProduct,
  isCompact,
  canUpdateProduct,
  modalVisible,
}): JSX.Element => {
  if (!bagProducts) return <React.Fragment />;

  return (
    <div className="my-bag__content">
      {bagProducts?.map((product) => {
        return (
          <MyBagProduct
            key={product.sku}
            {...{
              handleRemoveProduct,
              isCheckoutProduct,
              isCompact,
              product,
              canUpdateProduct,
              modalVisible,
            }}
          />
        );
      })}
    </div>
  );
};

export const MyBagMenu__Header: FC<IRedeemPointsHeader> = ({
  bagProducts,
  showBagMenu,
}): JSX.Element => {
  if (!bagProducts) return <React.Fragment />;

  return (
    <div className="my-bag-preview__header">
      <div className="my-bag-preview__header-title">
        <YuTitle size="S">
          <FormattedMessage id="page.redeem-points.shopping-bag" />
        </YuTitle>
        <YuParagraph size="M">
          <FormattedMessage
            id="page.redeem-points.{products}-no-products"
            values={{ products: _.sum(_.map(bagProducts, 'qty')) || 0 }}
          />
        </YuParagraph>
      </div>
      <YuIcon name="Cross" size="S" onClick={() => showBagMenu(false)} />
    </div>
  );
};

export const CheckOutCart: FC<{
  user?: IUser;
  showBagMenu?: React.Dispatch<boolean>;
  cartItems: number;
  path?: NextRouter;
  enabled: boolean;
}> = ({ user, showBagMenu, cartItems, path, enabled }): JSX.Element => {
  const excludePaths: string[] = [
    routePaths.PAGES.REDEEM_POINTS.MY_BAG,
    routePaths.PAGES.REDEEM_POINTS.CHECKOUT,
  ];

  if (!user) return <React.Fragment />;

  return (
    <a
      onClick={() => {
        if (!excludePaths.includes(path?.pathname) && enabled)
          showBagMenu(true);
      }}
    >
      <YuBadgeNumeric
        badgeContent={cartItems}
        size="S"
        description="Shopping Cart"
        variant={!enabled ? 'disabled' : 'primary'}
      >
        <YuIcon name="ShoppingBag" size="L" />
      </YuBadgeNumeric>
    </a>
  );
};

export const changeQuantity = (
  product: IRedeemPointsProduct,
  qty: number,
  bagProducts: IRedeemPointsProduct[]
) => {
  const modifiedProducts = bagProducts.map((bagProduct) => {
    if (bagProduct.sku === product.sku) {
      bagProduct.qty = qty;
    }
    return bagProduct;
  });
  return [{ ...product, qty }, modifiedProducts];
};

export const ProductQuantityButtons: FC<{
  qty: number;
  handleChangeQuantity: (qty: number) => void;
  maxQuantity: number;
}> = ({ handleChangeQuantity, qty, maxQuantity }) => {
  return (
    <YuQuantitySelector
      minValue={0}
      maxValue={maxQuantity || 999999}
      onSetQuantity={handleChangeQuantity}
      size="XS"
      value={qty}
      variant="nonEditable"
    />
  );

  /*
    return (
      <Row className="product__quantity-buttons">
        <Col
          xs={6}
          onClick={reduceItems}
          className={`icon-border ${
            qty === 0 ? 'icon__border-grey-semilight' : ''
          }`}
        >
          <i
            className={`icon icon--minus ${
              qty === 0 ? 'icon__grey-semilight' : ''
            }`}
          ></i>
        </Col>
        <Col xs={12}>
          <div className="quantity">{qty}</div>
        </Col>
        <Col
          xs={6}
          onClick={increaseItems}
          className={`icon-border ${
            maxQuantity <= qty ? 'icon__border-grey-semilight' : ''
          }`}
        >
          <i
            className={`icon icon--plus ${
              maxQuantity <= qty ? 'icon__grey-semilight' : ''
            }`}
          ></i>
        </Col>
      </Row>
    );

   */
};

export const Checkout__Shipping: FC<{
  addresses: ISalesforceAddress[];
  canCreateNew?: boolean;
  onCreateNewAddress?: () => void;
  onEditAddress: (address: ISalesforceAddress) => void;
  onSelectAddress: (address: ISalesforceAddress) => void;
  selectedAddress: ISalesforceAddress;
  salesforceCenter?: ISalesforceCenter;
  userPhone?: string;
  setUserPhone?: React.Dispatch<React.SetStateAction<string>>;
  isChallengeShoppingCart?: boolean;
}> = ({
  addresses,
  canCreateNew = false,
  onCreateNewAddress,
  onEditAddress,
  onSelectAddress,
  salesforceCenter,
  selectedAddress,
  userPhone,
  setUserPhone,
  isChallengeShoppingCart = false,
}) => {
  const { formatMessage } = useIntl();

  if (isUSAPlatform && !salesforceCenter) return <React.Fragment />;

  if (isUSAPlatform) {
    const BasicField = ({ content }: { content?: string }) =>
      content ? (
        <Row className="fs-personal-data fs-mask regular-body">{content}</Row>
      ) : (
        <React.Fragment />
      );

    const getCenterDirection = () => {
      let direction = centerDirectionStreet;
      if (centerDirectionNumber) direction += ` ${centerDirectionNumber}`;
      return direction;
    };

    const {
      centerName,
      centerDirectionNumber,
      centerDirectionCP,
      centerDirectionCity,
      centerDirectionCountry,
      centerDirectionStreet,
    } = salesforceCenter || {};

    return (
      <>
        <Col span={24}>
          <BasicField content={centerName} />
          <BasicField content={getCenterDirection()} />
          <BasicField content={centerDirectionCP} />
          <BasicField content={centerDirectionCity} />
          <BasicField content={centerDirectionCountry} />
        </Col>
      </>
    );
  }

  return (
    <>
      <YuFlex display="flex" flexDirection="column" gap="M">
        {
          // TODO: RESTORE
          // .filter(({ addressDelivery }) => addressDelivery)
          addresses?.map((address) => {
            const {
              addressCity,
              addressCP,
              addressID,
              addressName,
              addressNumber,
              addressStateName,
              addressStreet,
            } = address;

            return (
              <div className="yu-radio-custom-label">
                <YuRadioControl
                  key={addressID}
                  checked={addressID === selectedAddress?.addressID}
                  value={addressID}
                  onClick={() => {
                    onSelectAddress(address);
                  }}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  label={
                    <YuFlex
                      display="inline-flex"
                      flexDirection="row"
                      justifyContent="space-between"
                      gap="XXS"
                      width="100%"
                    >
                      <YuFlex
                        className="redeen-points-address-wrapper"
                        display="flex"
                        flexDirection="column"
                      >
                        <YuParagraph
                          className="fs-personal-data fs-mask"
                          style={{ display: 'flex', alignItems: 'center' }}
                          size="L"
                          fw="bold"
                        >
                          {addressName}
                          {selectedAddress?.addressID === addressID &&
                          addressExceedLimitForCurrentPlatform(address) ? (
                            <YuIcon
                              size="M"
                              style={{
                                marginLeft: 8,
                                display: 'inline',
                                pointerEvents: 'none',
                              }}
                              color="#FF9533"
                              name="AlertCircle"
                            />
                          ) : null}
                        </YuParagraph>
                        <YuParagraph
                          className="fs-personal-data fs-mask"
                          size="L"
                          style={{ whiteSpace: 'normal' }}
                        >
                          {`${addressStreet} ${
                            isESPlatform && addressNumber === null
                              ? ''
                              : `, ${addressNumber}`
                          } ${
                            shouldHidePostalCode(config.APP.PLATFORM) &&
                            addressCP === null
                              ? ''
                              : `, ${addressCP}`
                          }`}
                        </YuParagraph>
                        <YuParagraph
                          className="fs-personal-data fs-mask"
                          size="L"
                        >
                          {`${addressCity}, ${addressStateName}`}
                        </YuParagraph>
                      </YuFlex>
                      <div>
                        <YuButton
                          style={{ zIndex: 9, position: 'relative' }}
                          variant="ghost"
                          prependIcon="Pencil"
                          onClick={() => {
                            onEditAddress(address);
                          }}
                        >
                          <FormattedMessage id="page.redeem-points.checkout.edit" />
                        </YuButton>
                      </div>
                    </YuFlex>
                  }
                />
              </div>
            );
          })
        }
      </YuFlex>
      {canCreateNew && (
        <Radio value="newAddress" onClick={() => onCreateNewAddress()}>
          <span className="address-radio-name">
            <FormattedMessage id="shopping-cart.buy-modal.add-option" />
          </span>
        </Radio>
      )}
      {isChallengeShoppingCart && (
        <>
          <Row
            justify="start"
            align="middle"
            className="buy-modal__title-select buy-modal__title-select__contact"
          >
            <FormattedMessage id="contact-us.title" />
          </Row>
          <Row>
            <CheckoutPhoneInput
              {...{ userPhone, formatMessage, setUserPhone }}
            />
          </Row>
        </>
      )}
    </>
  );
};

export const buildRedeemProducts = (
  data: IRedeemPointsProductBase[]
): IRedeemPointsProduct[] => {
  const products: IRedeemPointsProduct[] = data?.map(
    (product: IRedeemPointsProductBase) => {
      const pathologies = product.pathologies
        .split(',')
        .filter((pathology: string) => Boolean(pathology.trim()));

      return { ...product, pathologies, price: product.points };
    }
  );

  return products || [];
};

export const buildRedeemProductsQuery = (
  size?: number,
  page?: number,
  filter?: IQueryRedeenPointsPrductsFilter,
  sortType?: string
): string => {
  let query = `page=${page}&size=${size}`;
  if (sortType) query += `&sortBy=${sortType}`;

  if (filter) {
    const keys = Object.keys(filter);

    keys.forEach((key) => {
      query += `&${key}=${filter[key]}`;
    });
  }
  return query;
};

export const buildFilter = (
  filters: IFilter[],
  id: string,
  items: IFilterItem[]
): IFilter[] => {
  if (!items || items?.length === 0) return filters;

  filters.push({ label: `productFilter.title.${id}`, id, items });

  return filters;
};

export const handleResizeGutterProducts = (): number => {
  let _gutter = -230;

  switch (true) {
    case window.innerWidth >= 1100:
      _gutter = -260;

      break;
    case window.innerWidth >= 1000:
      _gutter = -160;

      break;
    case window.innerWidth >= 930:
      _gutter = -450;

      break;
    case window.innerWidth >= 800:
      _gutter = -430;

      break;
    case window.innerWidth >= 768:
      _gutter = -400;

      break;
    case window.innerWidth >= 676:
      _gutter = -300;

      break;
    case window.innerWidth >= 600:
      _gutter = -250;

      break;

    case window.innerWidth >= 576:
      _gutter = -205;

      break;
    case window.innerWidth >= 526:
      _gutter = -180;
      break;
    case window.innerWidth >= 440:
      _gutter = -135;
      break;
    case window.innerWidth >= 400:
      _gutter = -125;
      break;
    case window.innerWidth >= 370:
      _gutter = -112;
      break;
    case window.innerWidth >= 350:
      _gutter = -105;
      break;
    case window.innerWidth >= 320:
      _gutter = -94;
      break;
  }
  return _gutter;
};

export const buildOrderData = ({
  targetAddress,
  user,
  salesforce,
  salesforceCenter,
  streeInOneLine = false,
}: {
  targetAddress: ISalesforceAddress;
  user: IUser;
  salesforce;
  salesforceCenter;
  streeInOneLine?: boolean;
}): IShippingAddress => {
  const {
    salesforce: { userFirstname, userSurname, userMail, userDocumentID },
  } = user;

  const addressData: IShippingAddress = {
    email: userMail,
    firstname: userFirstname,
    middlename: '',
    lastname: userSurname,
    telephone: salesforce.userPhone,
    prefix: 'Mr.',
    vat_id: userDocumentID,
  } as IShippingAddress;

  if (isUSAPlatform) {
    const {
      centerName,
      centerDirectionStreet,
      centerDirectionCP,
      centerDirectionCity,
      centerDirectionState,
      centerDirectionNumber,
    } = salesforceCenter || {};

    const regions: {
      region: string;
      region_code: string;
    }[] = config.APP.PROVINCES[config.APP.PLATFORM].filter(
      ({ code }) => code !== null
    );

    const { region, region_code } = regions.find(
      ({ region_code }) => region_code === centerDirectionState
    );

    return {
      ...addressData,
      city: centerDirectionCity,
      country_id: 'US',
      postcode: centerDirectionCP,
      region_code,
      region_id: region_code.toString(),
      region,
      street: [centerDirectionStreet, centerDirectionNumber],
      centerName: centerName,
    };
  }

  const regions: IRegion[] = config.APP.PROVINCES[config.APP.PLATFORM].filter(
    ({ code }) => code !== null
  );

  const { addressStreet, addressCP, addressCity, addressNumber, addressState } =
    targetAddress;

  const {
    name: region,
    id: region_id,
    code: region_code,
    countryCode,
  } = regions.find(({ id }) => id === addressState);

  let company = `${addressData.firstname} ${addressData.lastname}`;

  if ([PIN_PlatformID_Enum.PIN_ITALY].includes(config.APP.PLATFORM)) {
    company = `${addressData.lastname}, ${addressData.firstname}`;
  }

  return {
    ...addressData,
    city: addressCity,
    company,
    country_id: countryCode ?? config.REGISTER.COUNTRY_CODE,
    country: countryCode ?? config.REGISTER.COUNTRY_CODE,
    postcode: addressCP,
    region_code,
    region_id: region_id.toString(),
    region,
    street: streeInOneLine
      ? `${addressStreet} ${addressNumber}`
      : [addressStreet, addressNumber],
  };
};
