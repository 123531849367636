import {
  IResourceDetailList,
  IResourceList,
} from 'src/components/challenge/ChallengeInterfaces';
import { ResourceTypeDetailEnum } from 'src/shared/enums';
import { getTimeZone } from '../../../../utils';
import { IChallengeSurvey } from '../../../shared/models/challengeSurvey.model';
import { DateTime } from 'luxon';

abstract class ISurveyFiller {
  constructor(protected readonly detail: IResourceDetailList) {}

  abstract fill(survey: IChallengeSurvey): IChallengeSurvey;
}

class StartDateSurveyFilter extends ISurveyFiller {
  fill(survey: IChallengeSurvey): IChallengeSurvey {
    return {
      ...survey,
      startDate: DateTime.fromJSDate(new Date(this.detail.value), {
        zone: 'utc',
      }).setZone(getTimeZone()),
    };
  }
}

class DescriptionSurveyFilter extends ISurveyFiller {
  fill(survey: IChallengeSurvey): IChallengeSurvey {
    return {
      ...survey,
      description: this.detail.value,
    };
  }
}

class ButtonTextSurveyFilter extends ISurveyFiller {
  fill(survey: IChallengeSurvey): IChallengeSurvey {
    return {
      ...survey,
      buttonText: this.detail.value,
    };
  }
}

class EndDateSurveyFilter extends ISurveyFiller {
  fill(survey: IChallengeSurvey): IChallengeSurvey {
    return {
      ...survey,
      endDate: DateTime.fromJSDate(new Date(this.detail.value), {
        zone: 'utc',
      }).setZone(getTimeZone()),
    };
  }
}

class MessageOnFinishSurveyFilter extends ISurveyFiller {
  fill(survey: IChallengeSurvey): IChallengeSurvey {
    return {
      ...survey,
      messageOnFinish: this.detail.value,
    };
  }
}

class TypeformIdSurveyFilter extends ISurveyFiller {
  fill(survey: IChallengeSurvey): IChallengeSurvey {
    return {
      ...survey,
      typeformID: this.detail.value,
    };
  }
}

class ChallengeSurveyFactory {
  private readonly surveyFillers: Map<ResourceTypeDetailEnum, ISurveyFiller>;

  constructor(detail: IResourceDetailList) {
    this.surveyFillers = new Map();

    this.surveyFillers.set(
      ResourceTypeDetailEnum.SURVEY_START_DATE,
      new StartDateSurveyFilter(detail)
    );
    this.surveyFillers.set(
      ResourceTypeDetailEnum.SURVEY_DESCRIPTION,
      new DescriptionSurveyFilter(detail)
    );
    this.surveyFillers.set(
      ResourceTypeDetailEnum.SURVEY_BUTTON_TEXT,
      new ButtonTextSurveyFilter(detail)
    );
    this.surveyFillers.set(
      ResourceTypeDetailEnum.SURVEY_END_DATE,
      new EndDateSurveyFilter(detail)
    );
    this.surveyFillers.set(
      ResourceTypeDetailEnum.SURVEY_END_TEXT,
      new MessageOnFinishSurveyFilter(detail)
    );
    this.surveyFillers.set(
      ResourceTypeDetailEnum.SURVEY_TYPEFORM_ID,
      new TypeformIdSurveyFilter(detail)
    );
  }

  fill(
    { idResourceTypeD: { idResourceTypeD } }: IResourceDetailList,
    survey: IChallengeSurvey
  ): IChallengeSurvey {
    const surveyFiller = this.surveyFillers.get(idResourceTypeD);

    if (surveyFiller) {
      return surveyFiller.fill(survey);
    }

    return survey;
  }
}

class ChallengeSurveyBuilder {
  public build(resourceList: IResourceList): IChallengeSurvey {
    let survey: IChallengeSurvey;

    resourceList.resourceDetailList
      .filter((survey) => survey.status)
      .forEach((detail) => {
        const Factory = new ChallengeSurveyFactory(detail);

        survey = Factory.fill(detail, survey);
      });

    const { startDate, endDate } = survey;

    const isExpired =
      endDate.isValid && endDate <= DateTime.now().setZone(getTimeZone());
    const isStarted =
      startDate.isValid && startDate <= DateTime.now().setZone(getTimeZone());

    const isDisabled = false;
    const shouldRender = false;
    let dateToString = '';

    switch (true) {
      case startDate.isValid && endDate.isValid:
        dateToString = 'page.challenge.survey.dates';
        break;
      case startDate.isValid && !endDate.isValid:
        dateToString = 'page.challenge.survey.only-start-date';
        break;
      case !startDate.isValid && endDate.isValid:
      default:
        break;
    }

    return {
      ...survey,
      icon: 'survey',
      isDisabled,
      isExpired,
      isStarted,
      shouldRender,
      dateToString,
      idResource: resourceList.idResource,
    };
  }
}

export const builderSurvey = (
  resourceList: IResourceList
): IChallengeSurvey => {
  const builder = new ChallengeSurveyBuilder();

  return builder.build(resourceList);
};
