import 'antd/dist/antd.css';
import { config } from 'config/config';
import routePaths, { checkRoute, isRouteDefined } from 'config/routes';
import { AppContext, AppProps } from 'next/app';
import getConfig from 'next/config';
import Head from 'next/head';
import Router, { useRouter } from 'next/router';
import 'nprogress/nprogress.css';
import { useEffect, useState } from 'react';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import redirectTo from 'services/redirectTo';
import {
  fetchMaintenanceModes,
  fetchMaintenanceModesSSR,
  fetchPublicDisclaimers,
  fetchPublicMenu,
  IFetchMaintenanceMode,
} from 'src/actions/appActions';
import {
  fetchUserData,
  getUserInitialData,
  impersonate,
} from 'src/actions/authActions';
import BasicLayout from 'src/components/layout/BasicLayout';
import { getTranslations, intlLocaleProvider } from 'src/intl/config';
import { checkTranslationsLabels } from 'src/intl/translations.schema';
import { wrapper } from 'src/store';
import 'src/styles/global.scss';
import { GTMPageView } from 'utils/gtm';
import SkeletonLayout from 'src/components/layout/SkeletonLayout';
import { connect } from 'react-redux';
import { IUser } from 'src/shared/models';
import { UserStatusEnum } from 'src/shared/enums';
import { YuConfigProvider } from '@isdin/yuma-react-web-pin';
import { IntlProvider } from 'react-intl';
import _ from 'lodash';
import { InfoModalContextProvider } from '../components/common/InfoModal/InfoModalContext';
import { IMaintenanceMode } from '../shared/models/maintenanceMode.model';
import { getMaintenanceModeIfMatch } from '../helpers/getMaintenanceModeIfMatch';
import { IRootReducers } from '../reducers';

const { LANGUAGE_CODE, PLATFORM_PREFIX } = getConfig().publicRuntimeConfig;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const FS: any;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any;

interface ReduxProps extends AppProps {
  accessToken: string;
  refreshToken: string;
  user: IUser;
  userStatus: UserStatusEnum;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getUserInitialData: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fetchMaintenanceModes: (
    force: boolean
  ) => (
    dispatch: Dispatch<IFetchMaintenanceMode>,
    getState: () => IRootReducers
  ) => Promise<IMaintenanceMode[]>;
  maintenanceModes: IMaintenanceMode[];
}

const MyApp = (props: ReduxProps) => {
  // Google Tag Manager trigger
  const {
    maintenanceModes,
    accessToken,
    refreshToken,
    userStatus,
    user,
    getUserInitialData,
    fetchMaintenanceModes,
  } = props;

  const router = useRouter();

  useEffect(() => {
    const match = getMaintenanceModeIfMatch(maintenanceModes, router.pathname);
    if (match) window.location.href = match.toUrl;
  }, [router.pathname]);

  const localeMessages = intlLocaleProvider(LANGUAGE_CODE);
  const [fsPinIsReady, setFsPinIsReady] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const isLocalMode = config.APP.ENV === 'local';
  const [fsLastUserUUIDIdentified, setFsLastUserUUIDIdentified] =
    useState(null);

  useEffect(() => {
    if (user) {
      if (user.uuidUser !== fsLastUserUUIDIdentified && fsPinIsReady) {
        setFsLastUserUUIDIdentified(user.uuidUser);
        FS.identify(user.uuidUser, {
          role: user.idSpecialty.name,
        });
      }
    } else {
      setFsLastUserUUIDIdentified(null);
    }
  }, [user, fsPinIsReady]);

  useEffect(() => {
    if (!isLocalMode) {
      window['_fs_ready'] = function () {
        setFsPinIsReady(true);
      };
      setLoaded(true);
    }
    return () => {
      window['_fs_ready'] = _.noop;
    };
  }, []);

  const getFullStory = () => {
    return (
      loaded &&
      !isLocalMode && (
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html: `window['_fs_host'] = 'fullstory.com';
                window['_fs_script'] = 'edge.fullstory.com/s/fs.js';
                window['_fs_org'] = 'o-1PNDNX-na1';
                window['_fs_namespace'] = 'FS';
                (function(m,n,e,t,l,o,g,y){
                    if (e in m) {if(m.console && m.console.log) { m.console.log('FullStory namespace conflict. Please set window["_fs_namespace"].');} return;}
                    g=m[e]=function(a,b,s){g.q?g.q.push([a,b,s]):g._api(a,b,s);};g.q=[];
                    o=n.createElement(t);o.async=1;o.crossOrigin='anonymous';o.src='https://'+_fs_script;
                    y=n.getElementsByTagName(t)[0];y.parentNode.insertBefore(o,y);
                    g.identify=function(i,v,s){g(l,{uid:i},s);if(v)g(l,v,s)};g.setUserVars=function(v,s){g(l,v,s)};g.event=function(i,v,s){g('event',{n:i,p:v},s)};
                    g.anonymize=function(){g.identify(!!0)};
                    g.shutdown=function(){g("rec",!1)};g.restart=function(){g("rec",!0)};
                    g.log = function(a,b){g("log",[a,b])};
                    g.consent=function(a){g("consent",!arguments.length||a)};
                    g.identifyAccount=function(i,v){o='account';v=v||{};v.acctId=i;g(o,v)};
                    g.clearUserCookie=function(){};
                    g.setVars=function(n, p){g('setVars',[n,p]);};
                    g._w={};y='XMLHttpRequest';g._w[y]=m[y];y='fetch';g._w[y]=m[y];
                    if(m[y])m[y]=function(){return g._w[y].apply(this,arguments)};
                    g._v="1.3.0";
                })(window,document,window['_fs_namespace'],'script','user');`,
          }}
        ></script>
      )
    );
  };

  useEffect(() => {
    checkTranslationsLabels();
    const handleRouteChange = (url: string) => {
      GTMPageView(url);
    };
    const handleRouteStart = (pathname) => {
      const match = getMaintenanceModeIfMatch(maintenanceModes, pathname);
      if (match) {
        window.location.href = match.toUrl;
        router.events.emit('routeChangeError');
        throw 'Cancel route change'; // Necesario para detener la navegación
      }
    };
    const onFocus = () => {
      fetchMaintenanceModes(true);
    };

    window.addEventListener('focus', onFocus);
    Router.events.on('routeChangeStart', handleRouteStart);
    Router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      window.removeEventListener('focus', onFocus);
      Router.events.off('routeChangeStart', handleRouteStart);
      Router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, []);

  useEffect(() => {
    const isUserLogged = !!accessToken;
    if (isUserLogged) {
      getUserInitialData(accessToken, refreshToken, user, userStatus);
    }
  }, [accessToken]);

  return props.pageProps.pathname === '/health' ? (
    <props.Component {...props.pageProps} />
  ) : (
    <div className={`${props.pageProps.isPreview ? 'previewMode' : ''}`}>
      <Head>
        <meta
          key="meta-content-type"
          httpEquiv="Content-Type"
          content="text/html"
          charSet="utf-8"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>{config.APP.NAME}</title>
        {getFullStory()}
      </Head>

      <YuConfigProvider
        appLib="nextJS"
        locale={{
          locale: LANGUAGE_CODE,
          translations: (translations) => getTranslations(translations),
          supportedLocales: () => {
            return [
              'es-ES',
              'en-US',
              'de-DE',
              'es-AR',
              'es-CO',
              'es-PE',
              'es-CL',
              'es-MX',
              'es-GT',
              'it-IT',
              'pt-PT',
              'pt-BR',
            ];
          },
        }}
      >
        <IntlProvider
          key="app-intl-provider"
          locale={LANGUAGE_CODE}
          messages={localeMessages}
        >
          <InfoModalContextProvider>
            <BasicLayout>
              <SkeletonLayout>
                <props.Component {...props.pageProps} />
              </SkeletonLayout>
            </BasicLayout>
          </InfoModalContextProvider>
        </IntlProvider>
      </YuConfigProvider>
    </div>
  );
};

MyApp.getInitialProps = wrapper.getInitialAppProps(
  () => async (context: AppContext) => {
    const { Component, ctx } = context;

    const pathname = (ctx.req?.url ?? ctx.pathname).replace(
      PLATFORM_PREFIX,
      ''
    );
    const isPreview = Object.keys(ctx.query).includes('preview');
    await fetchPublicMenu(ctx);
    const impersonating = pathname.includes(routePaths.IMPERSONATE);
    const [maintenanceModes] = await Promise.all([
      fetchMaintenanceModesSSR(ctx),
      fetchPublicDisclaimers(ctx),
    ]);

    const match = getMaintenanceModeIfMatch(maintenanceModes, pathname);
    if (match) {
      ctx.res.writeHead(302, {
        Location: match.toUrl,
      });
      ctx.res.end();
    }

    switch (true) {
      case impersonating:
        await impersonate(ctx);
        break;
      case isRouteDefined(pathname, ctx.pathname?.replace(PLATFORM_PREFIX, '')):
        await fetchUserData(ctx, pathname);
        break;
      case pathname[0] === '?':
      case checkRoute(pathname, [routePaths.BASE]):
        await redirectTo(`${routePaths.INDEX}${pathname}`, ctx);
        break;
      case checkRoute(pathname, [routePaths.ERROR]):
        await fetchUserData(ctx, pathname);
        break;
      default:
        await redirectTo(
          `${routePaths.STATIC}?page=${pathname.replace('?', '&')}`,
          ctx
        );
        break;
    }

    return {
      pageProps: {
        ...(Component.getInitialProps
          ? await Component.getInitialProps(ctx)
          : {}),
        pathname: ctx.pathname,
        isPreview,
      },
    };
  }
);

const mapStateToProps = (state) => {
  return {
    maintenanceModes: state.app.maintenanceModes,
    accessToken: state.auth.accessToken,
    refreshToken: state.auth.refreshToken,
    user: state.auth.user,
    userStatus: state.auth.userStatus,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({ getUserInitialData, fetchMaintenanceModes }, dispatch);

const ConnectedMyApp = connect(mapStateToProps, mapDispatchToProps)(MyApp);

export default wrapper.withRedux(ConnectedMyApp);
