import { ChannelType } from 'src/shared/enums/platform.enum';
import { IRegion } from 'src/shared/models';

export const PIN_MX: IRegion[] = [
  {
    id: 'a0C5800000FiELcEAN',
    name: 'Aguascalientes',
    code: 'AGS',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEOPEA3',
    name: 'Baja California',
    code: 'BC',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEOQEA3',
    name: 'Baja California S',
    code: 'BCS',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiERKEA3',
    name: 'Ciudad de México',
    code: 'CDM',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiERAEA3',
    name: 'Chihuahua',
    code: 'CHI',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiER7EAN',
    name: 'Chiapas',
    code: 'CHS',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEQ8EAN',
    name: 'Campeche',
    code: 'CMP',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiERREA3',
    name: 'Coahuila',
    code: 'COA',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiERWEA3',
    name: 'Colima',
    code: 'COL',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiESgEAN',
    name: 'Distrito Federal',
    code: 'DF',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiET4EAN',
    name: 'Durango',
    code: 'DGO',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEV7EAN',
    name: 'Guerrero',
    code: 'GRO',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEV0EAN',
    name: 'Guanajuato',
    code: 'GTO',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEVkEAN',
    name: 'Hidalgo',
    code: 'HGO',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEWuEAN',
    name: 'Jalisco',
    code: 'JAL',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEb3EAF',
    name: 'Michoacán',
    code: 'MCH',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiETXEA3',
    name: 'Estado de México',
    code: 'MEX',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEbfEAF',
    name: 'Morelos',
    code: 'MOR',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEc6EAF',
    name: 'Nayarit',
    code: 'NAY',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEcvEAF',
    name: 'Nuevo León',
    code: 'NL',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEd0EAF',
    name: 'Oaxaca',
    code: 'OAX',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEeyEAF',
    name: 'Puebla',
    code: 'PUE',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEfGEAV',
    name: 'Quintana Roo',
    code: 'QR',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEfEEAV',
    name: 'Querétaro',
    code: 'QRO',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEhqEAF',
    name: 'Sinaloa',
    code: 'SIN',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEgtEAF',
    name: 'San Luis Potosí',
    code: 'SLP',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEiGEAV',
    name: 'Sonora',
    code: 'SON',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEitEAF',
    name: 'Tabasco',
    code: 'TAB',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEjjEAF',
    name: 'Tlaxcala',
    code: 'TLX',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEj2EAF',
    name: 'Tamaulipas',
    code: 'TMS',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEkwEAF',
    name: 'Veracruz',
    code: 'VER',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEmEEAV',
    name: 'Yucatán',
    code: 'YUC',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEmHEAV',
    name: 'Zacatecas',
    code: 'ZAC',
    channel: ChannelType.NATIONAL,
  },
].sort(({ name: nameA }, { name: nameB }) => {
  return nameA.localeCompare(nameB, 'es-ES');
});
