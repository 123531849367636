import { Col, notification, Row, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { config } from 'config/config';
import { DateTime } from 'luxon';
import React, { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { connect, useDispatch } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { postDataCall } from 'services/api';
import apiPaths from 'services/apiPaths';
import { ChallengeDetailsBox } from 'src/components/challenge/ChallengeDetailsBox';
import ChallengeResourceWrapper from 'src/components/challenge/ChallengeResourceWrapper';
import { IRootReducers } from 'src/reducers';
import {
  IResourceBehaviorsEnum,
  ResourceTypeDetailEnum,
} from 'src/shared/enums';
import { IChallengeResourceRender, IUser } from 'src/shared/models';
import { IArnSurvey } from 'src/shared/models/arnSurvey.model';
import { getResourceDetail, getShortDataFormatMoment } from '../../../utils';
import { getTimeZone } from 'utils/timeUtils';
import { IResourceList } from './ChallengeInterfaces';
import { updateUserPoints } from 'src/actions/authActions';

interface OwnProps extends IChallengeResourceRender {
  isChallengeComponent?: boolean;
  isCampaignComponent?: boolean;
  resource?: IResourceList;
  icon?: string;
  isCompleted?: boolean;
}

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnProps;

const ChallengeArnSurvey: FC<Props> = ({
  isChallengeComponent = true,
  isCampaignComponent,
  resource,
  resourceBehavior,
  user,
  icon,
  isCompleted,
}) => {
  const { formatMessage } = useIntl();
  let date: string;
  let disabled: boolean;
  let shouldRender: boolean;
  const dispatch = useDispatch();

  // ARN Survey with status = UserResourceEnum.DONE
  const isArnSurveyCompleted = isCompleted;

  const startDetail = getResourceDetail(
    resource,
    ResourceTypeDetailEnum.ARN_SURVEY_START_DATE
  );
  const endDetail = getResourceDetail(
    resource,
    ResourceTypeDetailEnum.ARN_SURVEY_END_DATE
  );
  const descriptionDetail = getResourceDetail(
    resource,
    ResourceTypeDetailEnum.ARN_SURVEY_DESCRIPTION
  );
  const end_message = getResourceDetail(
    resource,
    ResourceTypeDetailEnum.ARN_SURVEY_END_TEXT
  );
  const buttonDetail = getResourceDetail(
    resource,
    ResourceTypeDetailEnum.ARN_SURVEY_BUTTON_TEXT
  );

  const arnSurveyStart = DateTime.fromISO(startDetail?.value, {
    zone: getTimeZone(),
  });
  const arnSurveyEnd = DateTime.fromISO(endDetail?.value, {
    zone: getTimeZone(),
  });

  const validDates = arnSurveyStart.isValid || arnSurveyEnd.isValid;

  const started =
    arnSurveyStart.isValid &&
    arnSurveyStart <= DateTime.now().setZone(getTimeZone());
  const expired =
    arnSurveyEnd.isValid &&
    arnSurveyEnd <= DateTime.now().setZone(getTimeZone());

  switch (true) {
    case !validDates:
    // exists survey start date, but now is not on dates
    // eslint-disable-next-line no-fallthrough
    case arnSurveyStart.isValid && !started && !expired:
      disabled = true;
      if (isCampaignComponent) disabled = false;
      if (startDetail?.value && !started) disabled = true;

      shouldRender = resourceBehavior
        ? resourceBehavior.behavior === IResourceBehaviorsEnum.DISABLED
        : true;
      break;
    case !resourceBehavior:
    case resourceBehavior.status && started:
      shouldRender = true;
      disabled = false;
      break;
    case resourceBehavior.behavior === IResourceBehaviorsEnum.DISABLED:
      shouldRender = true;
      disabled = true;
      break;
    case resourceBehavior.behavior === IResourceBehaviorsEnum.HIDDEN:
      shouldRender = false;
      disabled = true;
      break;
  }

  const { arnSurvey } = resource;

  switch (true) {
    case arnSurveyStart.isValid && arnSurveyEnd.isValid:
      date = formatMessage(
        { id: 'page.challenge.survey.dates' },
        {
          startDate: arnSurveyStart.toFormat(getShortDataFormatMoment()),
          endDate: arnSurveyEnd.toFormat(getShortDataFormatMoment()),
        }
      );
      break;
    case arnSurveyStart.isValid && !arnSurveyEnd.isValid:
      date = formatMessage(
        { id: 'page.challenge.survey.only-start-date' },
        {
          startDate: arnSurveyStart.toFormat(getShortDataFormatMoment()),
        }
      );
      break;
    case !arnSurveyStart.isValid && arnSurveyEnd.isValid:
    default:
      break;
  }

  return (
    user && (
      <ArnSurvey
        {...{
          buttonText: buttonDetail?.value,
          date,
          description: descriptionDetail?.value,
          disabled,
          expired,
          isCampaignComponent,
          isChallengeComponent,
          isArnSurveyCompleted,
          idResource: resource?.idResource,
          shouldRender,
          arnSurveyData: arnSurvey,
          title: resource?.name,
          user,
          end_message: end_message?.value,
          icon,
          points: resource?.score?.points ?? 0,
          dispatch,
        }}
      />
    )
  );
};

export const ArnSurvey = ({
  buttonText,
  date,
  description,
  disabled = false,
  expired,
  isCampaignComponent,
  isChallengeComponent = false,
  isArnSurveyCompleted,
  idResource,
  shouldRender,
  arnSurveyData,
  title,
  user,
  end_message,
  icon,
  points,
  dispatch,
}: {
  buttonText?: string;
  date?: string;
  description?: string;
  disabled?: boolean;
  expired?: boolean;
  isCampaignComponent?: boolean;
  isChallengeComponent: boolean;
  isArnSurveyCompleted?: boolean;
  idResource: number;
  shouldRender: boolean;
  arnSurveyData: IArnSurvey;
  title?: string;
  user: IUser;
  end_message?: string;
  icon?: string;
  points: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: Dispatch<any>;
}) => {
  const [showArnSurvey, setShowArnSurvey] = useState(true);
  const [isArnSurveyStarted, setIsArnSurveyStarted] = useState(false);
  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const { formatNumber, formatMessage } = useIntl();

  if (!user || !arnSurveyData || arnSurveyData.token === null)
    return <React.Fragment />;

  const loadingSpinner = (
    <LoadingOutlined
      style={{
        fontSize: 50,
        color: 'red',
        marginTop: '100px',
        marginBottom: '100px',
      }}
      spin
    />
  );

  const { title: surveyTitle, content, image, url } = arnSurveyData;

  const handleShowArnSurvey = async () => {
    // Default language is english (set by the survey provider, not us)
    const languageCode = config.APP.LANGUAGE_CODE.substring(0, 2);

    setShowSpinner(true);

    try {
      await postDataCall({
        dataPath: `${apiPaths.CHALLENGES.USER_RESOURCE_ARN_SURVEY}/${idResource}`,
        data: {},
        callConfig: {},
      }).then((_response) => {
        const { token, score } = _response.data;

        setShowSpinner(false);

        window.open(
          `${config.APP.ARN_SURVEY_BASE_URL}${url}?token=${token}&lang=${languageCode}`
        );

        // To know that user just clicked the button and then we hide it
        // Info in 'isArnSurveyCompleted' is to know how to render the card at the start so is useless in this case
        setIsArnSurveyStarted(true);

        if (score && typeof score === 'number')
          dispatch(updateUserPoints(score));
      });
    } catch (error) {
      notification.error({
        message: formatMessage({ id: 'arn-survey.points.error' }),
      });
    }
  };

  let component;

  switch (true) {
    case showSpinner:
      component = <Spin indicator={loadingSpinner} />;
      break;
    case !showSpinner:
      component = shouldRender && (
        <Row
          justify="center"
          // TODO: use value from redux-store: similar to "ChallengeQuiz"
          className={`challengeDetail--quiz ${
            isCampaignComponent ? 'challengeDetail--campaign-quiz' : 'old-quiz'
          }`}
          id="challengeDetail--survey"
        >
          <Col span={24}>
            <ChallengeDetailsBox
              allowRetry={false}
              completed={isArnSurveyCompleted}
              date={date}
              description={content.trim()}
              detailTitle={buttonText}
              disabled={disabled}
              expired={expired}
              handleShowSurvey={handleShowArnSurvey}
              image={image}
              isSurvey={true}
              points={points ?? 0}
              setShowComponent={setShowArnSurvey}
              showQuiz={showArnSurvey}
              title={surveyTitle}
              isCampaignComponent={isCampaignComponent}
              end_message={end_message}
              showButton={!isArnSurveyStarted}
              /*
              loading={renderLoader}
              */
            />
          </Col>
        </Row>
      );
      break;
  }

  return (
    <ChallengeResourceWrapper
      children={component}
      description={description}
      icon={isChallengeComponent && icon}
      bottomLabel={
        isCampaignComponent &&
        isArnSurveyCompleted && {
          label: 'page.challenge.congratulations-survey-completed',
          style: 'completed',
        }
      }
      points={
        isCampaignComponent &&
        formatMessage(
          { id: 'page.challenge.plus-sign-{points}-points' },
          { points: formatNumber(points) }
        )
      }
      title={title}
    />
  );
};

const mapStateToProps = (state: IRootReducers) => {
  return {
    user: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ChallengeArnSurvey);
