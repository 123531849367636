import { notification } from 'antd';
import { config } from 'config/config';
import routePaths, { checkRoute, isRouteDefined } from 'config/routes';
import { Dispatch } from 'redux';
import * as api from 'services/api';
import apiPaths from 'services/apiPaths';
import { builderChallenge } from 'src/components/challenge/builder';
import { IChatCustomerCare } from 'src/components/chatCustomerCare/ChatCustomerCare.utils';
import {
  ChallengeDetail,
  ICartTotals,
  ICategory,
  IChallenge,
  IChallengesList,
  IContentPage,
  IDisclaimerDTO,
  IDiscountCoupon,
  IFilter,
  IFilterItem,
  ILabel,
  IMenu,
  IQueryRedeenPointsPrductsFilter,
  IRedeemPointsCart,
  IRedeemPointsProduct,
  IRegion,
  IResponseChallengesList,
  ISalesforceCenter,
  ISpecialty,
  IState,
  IUser,
} from 'src/shared/models';
import { appTypes } from 'src/types/appTypes';
import {
  buildRedeemProducts,
  buildRedeemProductsQuery,
  prepareChallengeFiltersResult,
} from '../../utils';
import {
  CHALLENGE_TYPE_KEYS,
  CHALLENGE_TYPE_VALUES,
  ChallengeTypeEnum,
  ReferralProduct,
} from '../shared/enums';
import { mapLegacyURLs } from '../components/layout/Disclaimer';
import { IRootReducers } from 'src/reducers';
import _ from 'lodash';
import { NextPageContext } from 'next';
import { IMaintenanceMode } from '../shared/models/maintenanceMode.model';

const { BASE_URL } = config.API;

/*-------------- APP INTERFACES --------------- */
interface IFetchPublicMenu {
  type: appTypes.APP_FETCH_PUBLIC_MENU;
  payload: {
    publicMenu: IMenu[];
  };
}

interface IFetchShoppingProducts {
  type: appTypes.APP_FETCH_SHOPPING_PRODUCTS;
  payload: {
    shoppingProducts; // TODO: Add interface
  };
}

interface IFetchStates {
  type: appTypes.APP_FETCH_STATES;
  payload: { stateList: IState };
}

interface IUpdateSortByProduct {
  type: appTypes.APP_UPDATE_SORTBY;
  payload: { sortBy: string };
}

interface IUpdateLoggingIn {
  type: appTypes.APP_UPDATE_LOGGING_IN;
  payload: { isLoggingIn: boolean };
}

interface IFetchSpecialties {
  type: appTypes.APP_FETCH_SPECIALTIES;
  payload: { specialtyList: ISpecialty[] };
}

// FIXME: BORRAR categories
interface IFetchCategories {
  type: appTypes.APP_FETCH_CATEGORIES;
  payload: { categoryList: ICategory[] };
}

interface IFetchBrands {
  type: appTypes.APP_FETCH_BRANDS;
  payload: { brandList: IFilterItem[] };
}

export interface IProductsLimit {
  maxProducts: number;
  days: number;
  limited: boolean;
}

interface IFetchProductsLimit {
  type: appTypes.APP_FETCH_PRODUCTS_LIMIT;
  payload: { productsLimit: IProductsLimit };
}

export interface IOrderedProductQty {
  sku: string;
  qtyOrdered: number;
}

// FIXME: BORRAR pathologies
interface IFetchPahologies {
  type: appTypes.APP_FETCH_PATHOLOGIES;
  payload: { pathologyList: IFilterItem[] };
}

// FIXME:
interface IFetchFilters {
  type: appTypes.APP_FETCH_FILTERS;
  payload: {
    challengeType: CHALLENGE_TYPE_VALUES;
    filterList: IFilter[];
    filtersLoaded: boolean;
  };
}

interface IFetchRedeemPointsProducts {
  type: appTypes.APP_FETCH_REDEEM_POINTS_PRODUCTS;
  payload: {
    products: IRedeemPointsProduct[];
    totalProductPages: number;
    totalProductElements: number;
  };
}

interface IFetchLabelRedeemPRoducts {
  type: appTypes.APP_FETCH_LABEL_REDEEM_PRODUCTS;
  payload: {
    labels: ILabel[];
  };
}

export interface IUpdateChallenges {
  type: appTypes.APP_UPDATE_CHALLENGES;
  payload: {
    challengeType: CHALLENGE_TYPE_VALUES;
    challengeList: {
      total: number;
      challenges: ChallengeDetail[];
    };
  };
}

export interface IInsertChallenges {
  type: appTypes.APP_INSERT_CHALLENGES;
  payload: {
    challengeKey: CHALLENGE_TYPE_VALUES;
    challengeList: IChallengesList;
  };
}

export interface IInsertAnnualCampaign {
  type: appTypes.APP_INSERT_ANNUAL_CAMPAIGN;
  payload: {
    challenge: ChallengeDetail;
  };
}

interface IFetchChatCustomerCareConfiguration {
  type: appTypes.APP_FETCH_CHAT_CUSTOMER_CARE_CONFIGURATION;
  payload: { chatCustomerCare: IChatCustomerCare };
}

interface IFetchRecruitmentDateCommercialSubscription {
  type: appTypes.APP_FETCH_COMMERCIAL_SUBSCRIPTION_RECRUITMENT;
  payload: {
    commercialSubscriptionRecruitment: { date: string; days: string };
  };
}

interface IFetchDisclaimers {
  type: appTypes.APP_FETCH_DISCLAIMERS;
  payload: { private: IDisclaimerDTO[] };
}

interface IFetchPublicDisclaimers {
  type: appTypes.APP_FETCH_PUBLIC_DISCLAIMERS;
  payload: { public: IDisclaimerDTO[] };
}

interface IFetchReferralProductsList {
  type: appTypes.APP_FETCH_REFERRAL_PRODUCTS_LIST;
  payload: { referralProductsList: { products: ReferralProduct[] } };
}

interface IFetchCampaings {
  type: appTypes.APP_FETCH_CAMPAINGS;
  payload: { total: number; campaigns: ChallengeDetail[]; filters: IFilter[] };
}

interface IFetchTalks {
  type: appTypes.APP_FETCH_TALKS;
  payload: { total: number; talks: ChallengeDetail[]; filters: IFilter[] };
}

interface IFetchPearls {
  type: appTypes.APP_FETCH_PEARLS;
  payload: { total: number; pearls: ChallengeDetail[]; filters: IFilter[] };
}

interface IFetchTrainings {
  type: appTypes.APP_FETCH_TRAININGS;
  payload: { total: number; trainings: ChallengeDetail[]; filters: IFilter[] };
}

export interface ISetChallenge {
  type: appTypes.APP_SET_CHALLENGE;
  payload: { challenge: ChallengeDetail };
}

interface IFetchEmbracePractices {
  type: appTypes.APP_FETCH_EMBRACE_PRACTICES;
  payload: { embracePractices: ISalesforceCenter[] };
}

export interface IFetchCertifiedCourses {
  type: appTypes.APP_FETCH_CERTIFIED_COURSES;
  payload: { certifiedCourses: ChallengeDetail[] };
}

interface IFetchHomePage {
  type: appTypes.APP_FETCH_HOME_PAGE;
  payload: { homePage: IContentPage };
}

export interface IFetchMaintenanceMode {
  type: appTypes.APP_FETCH_MAINTENANCE_MODE;
  payload: { maintenanceMode: IMaintenanceMode[] };
}

interface IFetchLIWWPractices {
  type: appTypes.APP_FETCH_LIWW_PRACTICES;
  payload: { LIWWPractices: ISalesforceCenter[] };
}

interface IFetchUSAPractices {
  type: appTypes.APP_FETCH_USA_PRACTICES;
  payload: { USAPractices: ISalesforceCenter[] };
}

interface ISetSalesforceCenter {
  type: appTypes.APP_SET_SALESFORCE_CENTER;
  payload: {
    salesforceCenter: ISalesforceCenter;
  };
}

interface ISetUserMenu {
  type: appTypes.APP_FETCH_USER_MENU;
  payload: {
    userMenu: null | IMenu[];
  };
}

interface ISetUserHome {
  type: appTypes.APP_FETCH_USER_HOME;
  payload: {
    userHome: null | IContentPage;
  };
}

interface ISetStaticPage {
  type: appTypes.APP_SET_STATIC_PAGE;
  payload: {
    staticPageConfig: {
      showFooter: boolean;
      showHeader: boolean;
    };
  };
}

interface IShowBagMenu {
  type: appTypes.APP_SHOW_BAG_MENU;
  payload: { showSiderMenu: boolean };
}

interface IAddBagProduct {
  type: appTypes.APP_ADD_BAG_PRODUCT;
  payload: { cart: IRedeemPointsCart };
}

interface IUpdateBagProduct {
  type: appTypes.APP_UPDATE_BAG_PRODUCT;
  payload: { cart: IRedeemPointsCart };
}

interface IRemoveBagProduct {
  type: appTypes.APP_REMOVE_BAG_PRODUCT;
  payload: { cart: IRedeemPointsCart };
}

interface IAddOrRemoveDiscountCoupon {
  type: appTypes.APP_ADD_OR_REMOVE_DISCOUNT_COUPON;
  payload: { couponData: IDiscountCoupon; cartTotals: ICartTotals };
}

interface IFetchCart {
  type: appTypes.APP_FETCH_CART;
  payload: { cart: IRedeemPointsCart };
}

interface IProvinceCountries {
  type: appTypes.APP_SET_PROVINCES_COUNTRIES;
  payload: { provinces: IRegion[] };
}

interface ICountry {
  type: appTypes.APP_SET_COUNTRY;
  payload: { country: string };
}

interface IAppReset {
  type: appTypes.APP_RESET;
}

export type AppActionTypes =
  | IAddBagProduct
  | IAddOrRemoveDiscountCoupon
  | ICountry
  | IFetchBrands
  | IFetchCampaings
  | IFetchCart
  | IFetchProductsLimit
  | IFetchCategories
  | IFetchCertifiedCourses
  | IFetchChatCustomerCareConfiguration
  | IFetchEmbracePractices
  | IFetchFilters
  | IFetchLabelRedeemPRoducts
  | IFetchLIWWPractices
  | IFetchPahologies
  | IFetchPublicMenu
  | IFetchRedeemPointsProducts
  | IFetchDisclaimers
  | IFetchPublicDisclaimers
  | IFetchShoppingProducts
  | IFetchSpecialties
  | IFetchReferralProductsList
  | IFetchStates
  | IFetchTalks
  | IFetchTrainings
  | IFetchHomePage
  | IFetchUSAPractices
  | IProvinceCountries
  | IRemoveBagProduct
  | ISetChallenge
  | ISetSalesforceCenter
  | ISetStaticPage
  | ISetUserHome
  | ISetUserMenu
  | IShowBagMenu
  | IUpdateBagProduct
  | IUpdateChallenges
  | IInsertChallenges
  | IInsertAnnualCampaign
  | IUpdateSortByProduct
  | IAppReset
  | IUpdateLoggingIn
  | IFetchRecruitmentDateCommercialSubscription
  | IFetchMaintenanceMode;

/*-------------- APP ACTIONS --------------- */

export const updateIsLoggingIn =
  (loggingIn: boolean) => async (dispatch: Dispatch<IUpdateLoggingIn>) => {
    dispatch({
      type: appTypes.APP_UPDATE_LOGGING_IN,
      payload: { isLoggingIn: loggingIn },
    });
  };

export const fetchPublicMenu = async (ctx) => {
  const reduxMenu = ctx.store.getState().app.publicMenu;
  await ctx.store.dispatch({
    type: appTypes.APP_SET_STATIC_PAGE,
    payload: {
      staticPageConfig: {
        showFooter: true,
        showHeader: true,
      },
    },
  });

  if (reduxMenu === null) {
    try {
      const menu = await fetch(`${BASE_URL}${apiPaths.CALL.PUBLIC_MENU}`, {
        method: 'GET',
      });

      const publicMenu: IMenu[] = menu.ok ? await menu.json() : null;
      const _publicMenu = publicMenu?.map((menu) => {
        return {
          ...menu,
          subMenu: menu.subMenu.map((submenu) => {
            return {
              ...submenu,
              hrefAs: submenu?.route,
              href: checkRoute(submenu.route, routePaths)
                ? submenu.route
                : `/static?page=${submenu.route}`,
            };
          }),
        };
      });

      ctx.store.dispatch({
        type: appTypes.APP_FETCH_PUBLIC_MENU,
        payload: { publicMenu: _publicMenu },
      });
    } catch (err) {
      console.error(err);
    }
  }
};

export const fetchShoppingProducts =
  () => async (dispatch: Dispatch<IFetchShoppingProducts>) => {
    try {
      const response = await api.getDataCall({
        dataPath: apiPaths.MAGENTO.GET_SHOPPING_CART_PRODUCTS,
        callConfig: {},
      });

      const shoppingProducts = response.data;

      dispatch({
        type: appTypes.APP_FETCH_SHOPPING_PRODUCTS,
        payload: { shoppingProducts },
      });

      return;
    } catch (err) {
      console.error(err);
    }
  };

export const fetchStates = () => async (dispatch: Dispatch<IFetchStates>) => {
  try {
    const response = await api.getDataCall({
      dataPath: apiPaths.MAGENTO.COUNTRIES,
      callConfig: {},
    });

    const stateList: IState = response.data;

    dispatch({
      type: appTypes.APP_FETCH_STATES,
      payload: { stateList },
    });

    return;
  } catch (err) {
    console.error(err);
  }
};

export const fetchSpecialties =
  () => async (dispatch: Dispatch<IFetchSpecialties>) => {
    try {
      const response = await api.getSpecialties({
        dataPath: apiPaths.CALL.SPECIALTY_LIST,
      });

      if (response?.status === 200 && response.data) {
        dispatch({
          type: appTypes.APP_FETCH_SPECIALTIES,
          payload: { specialtyList: response.data },
        });
      }
      return;
    } catch (err) {
      console.error(err);
    }
  };

export const fetchEmbracePractices =
  (postalCode: string) =>
  async (dispatch: Dispatch<IFetchEmbracePractices>) => {
    try {
      const response = await api.getDataCall({
        dataPath: `${apiPaths.CALL.EMBRACE_PRACTICES}?centerDirectionCP=${postalCode}`,
        callConfig: {},
      });

      const embracePractices: ISalesforceCenter[] = response.data.map(
        (center: ISalesforceCenter) => {
          return {
            ...center,
            name: center.centerName,
            id: center.saleforceID,
          };
        }
      );

      dispatch({
        type: appTypes.APP_FETCH_EMBRACE_PRACTICES,
        payload: { embracePractices },
      });
    } catch (err) {
      console.error(err);
    }
  };

export const updateSortByProducts =
  (sortBy: string) => (dispatch: Dispatch<IUpdateSortByProduct>) => {
    dispatch({
      type: appTypes.APP_UPDATE_SORTBY,
      payload: { sortBy: sortBy },
    });
  };

export const fetchLIWWPractices =
  (postalCode: string) => async (dispatch: Dispatch<IFetchLIWWPractices>) => {
    try {
      const response = await api.getDataCall({
        dataPath: `${apiPaths.CALL.LIWW_PRACTICES}?centerDirectionCP=${postalCode}`,
        callConfig: {},
      });

      const LIWWPractices: ISalesforceCenter[] = response.data.map(
        (center: ISalesforceCenter) => {
          return {
            ...center,
            name: center.centerName,
            id: center.centerID,
          };
        }
      );

      dispatch({
        type: appTypes.APP_FETCH_LIWW_PRACTICES,
        payload: { LIWWPractices },
      });
    } catch (err) {
      console.error(err);
    }
  };

export const fetchUSAPractices =
  (postalCode: string) => async (dispatch: Dispatch<IFetchUSAPractices>) => {
    try {
      const response = await api.getDataCall({
        dataPath: `${apiPaths.CALL.USA_PRACTICES}?centerDirectionCP=${postalCode}`,
        callConfig: {},
      });

      const USAPractices: ISalesforceCenter[] = _.map(
        response.data,
        (center: ISalesforceCenter) => {
          return {
            ...center,
            name: center.centerName,
            id: center.centerID,
          };
        }
      );

      dispatch({
        type: appTypes.APP_FETCH_USA_PRACTICES,
        payload: { USAPractices },
      });
    } catch (err) {
      console.error(err);
    }
  };

export const fetchBrands = () => async (dispatch: Dispatch<IFetchBrands>) => {
  try {
    const response = await api.getApiCombo(apiPaths.COMBO_IDS.BRANDS);
    if (response?.status === 200 && response?.data) {
      const brandList: IFilterItem[] = response?.data.map((brand) => {
        return { ...brand, isChecked: false };
      });
      dispatch({
        type: appTypes.APP_FETCH_BRANDS,
        payload: { brandList },
      });
    }
  } catch (err) {
    console.error(err);
  }
};

export const fetchProductsLimit =
  () =>
  async (
    dispatch: Dispatch<IFetchProductsLimit>,
    getState: () => IRootReducers
  ) => {
    try {
      const response = await api.getApiCombo(apiPaths.COMBO_IDS.PRODUCTS_LIMIT);
      if (
        response.status === 200 &&
        response?.data &&
        getState().auth.accessToken
      ) {
        const productsLimit: IProductsLimit = {
          maxProducts: Number(
            response.data.find((value) => value.description === 'MaxProducts')
              .value
          ),
          days: Number(
            response.data.find((value) => value.description === 'Days').value
          ),
          limited: Boolean(
            response.data.find((value) => value.description === 'Limited')
              .value === 'true'
          ),
        };
        dispatch({
          type: appTypes.APP_FETCH_PRODUCTS_LIMIT,
          payload: {
            productsLimit: productsLimit,
          },
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

// FIXME: BORRAR categories
export const fetchCategories =
  () => async (dispatch: Dispatch<IFetchCategories>) => {
    try {
      const response = await api.getApiCombo(apiPaths.COMBO_IDS.CATEGORIES);

      const categoryList: ICategory[] = response?.data || [];

      dispatch({
        type: appTypes.APP_FETCH_CATEGORIES,
        payload: { categoryList },
      });
    } catch (err) {
      console.error(err);
    }
  };

// FIXME: BORRAR pathologies
export const fetchPahologies =
  () => async (dispatch: Dispatch<IFetchPahologies>) => {
    try {
      const response = await api.getApiCombo(apiPaths.COMBO_IDS.PATHOLOGIES);
      if (response?.status === 200 && response?.data) {
        const pathologyList: IFilterItem[] = response?.data.map((pathology) => {
          return { ...pathology, isChecked: false };
        });

        dispatch({
          type: appTypes.APP_FETCH_PATHOLOGIES,
          payload: { pathologyList },
        });
      }
    } catch (err) {
      console.error(err);
      if (err.response?.data?.message)
        notification.error({
          message: err.response?.data?.message,
          duration: 3,
        });
    }
  };

export const updateFilters = async (
  ctx: NextPageContext,
  challengeType: ChallengeTypeEnum,
  filterList: IFilter[]
) => {
  ctx.store.dispatch({
    type: appTypes.APP_FETCH_FILTERS,
    payload: {
      filtersLoaded: true,
      challengeType: CHALLENGE_TYPE_KEYS[challengeType],
      filterList: filterList.filter(({ items }) => items.length > 0),
    },
  });
};

export const fetchChatCustomerCareConfigurations =
  () => async (dispatch: Dispatch<IFetchChatCustomerCareConfiguration>) => {
    try {
      const response = await api.getDataCall({
        dataPath: apiPaths.CALL.CHAT_CUSTOMER_CARE_CONFIGURATION,
        callConfig: {},
      });

      dispatch({
        type: appTypes.APP_FETCH_CHAT_CUSTOMER_CARE_CONFIGURATION,
        payload: { chatCustomerCare: response.data },
      });
    } catch (err) {
      console.error(err);
      if (err.response?.data?.message)
        notification.error({
          message: err.response?.data?.message,
          duration: 3,
        });
      return false;
    }
  };

export const fetchCommercialSubscriptionRecruitmentDate =
  (type: string) =>
  async (dispatch: Dispatch<IFetchRecruitmentDateCommercialSubscription>) => {
    try {
      const response = await api.getDataCall({
        dataPath: `${apiPaths.CALL.LIST_VALUES_BY_TYPE}/${type}`,
        callConfig: {},
      });

      dispatch({
        type: appTypes.APP_FETCH_COMMERCIAL_SUBSCRIPTION_RECRUITMENT,
        payload: {
          commercialSubscriptionRecruitment: {
            date: response.data[0].value,
            days: response.data[1].value,
          },
        },
      });
    } catch (error) {
      console.error(error);
      if (error.response?.data?.message)
        notification.error({
          message: error.response?.data?.message,
          duration: 3,
        });
      return false;
    }
  };

export const fetchReferralProductsList =
  () => async (dispatch: Dispatch<IFetchReferralProductsList>) => {
    try {
      const response = await api.getDataCall({
        dataPath: apiPaths.CALL.USER_REFERRAL_DATA,
        callConfig: {},
      });

      dispatch({
        type: appTypes.APP_FETCH_REFERRAL_PRODUCTS_LIST,
        payload: { referralProductsList: { products: response.data.products } },
      });
    } catch (error) {
      console.error(error);
      if (error.response?.data?.message)
        notification.error({
          message: error.response?.data?.message,
          duration: 3,
        });
      return false;
    }
  };

export const fetchCampaings =
  (user: IUser, filters: IFilter[], size: number) =>
  async (dispatch: Dispatch<IFetchCampaings>): Promise<void> => {
    try {
      const _filters = prepareChallengeFiltersResult(filters);

      const url = `${apiPaths.CHALLENGES.GET_CAMPAIGNS}?size=${size}${_filters}`;

      const response = await api.getDataCall({
        dataPath: url,
        callConfig: {},
      });

      const data: IResponseChallengesList = response.data;
      const campaigns: ChallengeDetail[] = data.challenges.map(
        (challenge: IChallenge) => builderChallenge(challenge, user)
      );

      dispatch({
        type: appTypes.APP_FETCH_CAMPAINGS,
        payload: { total: response.data.total, campaigns, filters },
      });
    } catch (err) {
      console.error(err);
      if (err.response?.data?.message)
        notification.error({
          message: err.response?.data?.message,
          duration: 3,
        });
    }
  };

export const fetchTrainings =
  (user: IUser, filters: IFilter[], size: number) =>
  async (dispatch: Dispatch<IFetchTrainings>) => {
    try {
      const _filters = prepareChallengeFiltersResult(filters);
      const url = `${apiPaths.CHALLENGES.GET_TRAININGS}?size=${size}${_filters}`;

      const response = await api.getDataCall({
        dataPath: url,
        callConfig: {},
      });

      const data: IResponseChallengesList = response.data;
      const trainings: ChallengeDetail[] = data.challenges.map(
        (challenge: IChallenge) => builderChallenge(challenge, user)
      );

      dispatch({
        type: appTypes.APP_FETCH_TRAININGS,
        payload: {
          total: response.data.total,
          trainings,
          filters,
        },
      });
    } catch (err) {
      console.error(err);
      if (err.response?.data?.message)
        notification.error({
          message: err.response?.data?.message,
          duration: 3,
        });
      return false;
    }
  };

export const fetchTalks =
  (user: IUser, filters: IFilter[], size: number) =>
  async (dispatch: Dispatch<IFetchTalks>) => {
    try {
      const _filters = prepareChallengeFiltersResult(filters);
      const url = `${apiPaths.CHALLENGES.GET_EVENTS}?size=${size}${_filters}`;

      const response = await api.getDataCall({
        dataPath: url,
        callConfig: {},
      });

      const data: IResponseChallengesList = response.data;
      const events: ChallengeDetail[] = data.challenges.map(
        (challenge: IChallenge) => builderChallenge(challenge, user)
      );
      dispatch({
        type: appTypes.APP_FETCH_TALKS,
        payload: {
          total: response.data.total,
          talks: events,
          filters,
        },
      });
    } catch (err) {
      console.error(err);
      if (err.response?.data?.message)
        notification.error({
          message: err.response?.data?.message,
          duration: 3,
        });
      return false;
    }
  };

export const fetchPearls =
  (user: IUser, filters: IFilter[], size: number) =>
  async (dispatch: Dispatch<IFetchPearls>) => {
    try {
      const _filters = prepareChallengeFiltersResult(filters);
      const url = `${apiPaths.CHALLENGES.GET_PEARLS}?size=${size}${_filters}`;

      const response = await api.getDataCall({
        dataPath: url,
        callConfig: {},
      });

      const data: IResponseChallengesList = response.data;
      const pearls: ChallengeDetail[] = data.challenges.map(
        (challenge: IChallenge) => builderChallenge(challenge, user)
      );
      dispatch({
        type: appTypes.APP_FETCH_PEARLS,
        payload: {
          total: response.data.total,
          pearls,
          filters,
        },
      });
    } catch (err) {
      console.error(err);
      if (err.response?.data?.message)
        notification.error({
          message: err.response?.data?.message,
          duration: 3,
        });
      return false;
    }
  };

export const fetchCertifiedCourses = (user: IUser, filters: IFilter[]) => {
  async (dispatch: Dispatch<IFetchCertifiedCourses>) => {
    try {
      const _filters = prepareChallengeFiltersResult(filters);
      const url = `${apiPaths.CHALLENGES.GET_CERTIFIED_COURSES}?size=8${_filters}`;

      const response = await api.getDataCall({
        dataPath: url,
        callConfig: {},
      });

      const data: IResponseChallengesList = response.data;
      const certifiedCourses: ChallengeDetail[] = data.challenges.map(
        (challenge: IChallenge) => builderChallenge(challenge, user)
      );

      dispatch({
        type: appTypes.APP_FETCH_CERTIFIED_COURSES,
        payload: {
          total: response.data.total,
          certifiedCourses,
          filters,
        },
      });
    } catch (error) {
      console.error(error);
      if (error.response?.data?.message)
        notification.error({
          message: error.response?.data?.message,
          duration: 3,
        });
      return false;
    }
  };
};

export const fetchLabelRedeemProducts =
  () => async (dispatch: Dispatch<IFetchLabelRedeemPRoducts>) => {
    try {
      const respLabels = await api.getLabelRedeemProducts();
      const labels: ILabel[] = respLabels?.data?.content;
      dispatch({
        type: appTypes.APP_FETCH_LABEL_REDEEM_PRODUCTS,
        payload: {
          labels: labels,
        },
      });
    } catch (err) {
      console.error(err);
      if (err.response?.data?.message)
        notification.error({
          message: err.response?.data?.message,
          duration: 3,
        });
      return false;
    }
  };

export const fetchRedeemPointsProducts =
  (params: {
    size: number;
    page: number;
    currentProducts: IRedeemPointsProduct[];
    filter?: IQueryRedeenPointsPrductsFilter;
    sortType?: string;
  }) =>
  async (dispatch: Dispatch<IFetchRedeemPointsProducts>) => {
    try {
      const response = await api.getRedeemPointsProducts(
        buildRedeemProductsQuery(
          params.size,
          params.page,
          params.filter,
          params.sortType
        )
      );

      const products = response?.data?.content;

      const redeem_points_products: IRedeemPointsProduct[] = [
        ...params.currentProducts,
        ...buildRedeemProducts(products),
      ]?.filter(
        // Filter duplicates
        (currProduct, index, productList) =>
          index ===
          productList.findIndex((product) => product.sku === currProduct.sku)
      );

      dispatch({
        type: appTypes.APP_FETCH_REDEEM_POINTS_PRODUCTS,
        payload: {
          products: redeem_points_products,
          totalProductPages: response?.data?.totalPages ?? 0,
          totalProductElements: response?.data?.totalElements ?? 0,
        },
      });
    } catch (err) {
      console.error(err);
      if (err.response?.data?.message)
        notification.error({
          message: err.response?.data?.message,
          duration: 3,
        });
      return false;
    }
  };

export const fetchHomePage =
  (accessToken: string) =>
  async (dispatch: Dispatch<IFetchHomePage | ISetStaticPage>) => {
    const apiPath = accessToken
      ? apiPaths.CALL.USER_HOME
      : apiPaths.CALL.PUBLIC_HOME;
    try {
      const response = await api.getDataCall({
        dataPath: apiPath,
        callConfig: {},
      });
      dispatch({
        type: appTypes.APP_SET_STATIC_PAGE,
        payload: {
          staticPageConfig: {
            showFooter: response.data.footer,
            showHeader: response.data.header,
          },
        },
      });
      dispatch({
        type: appTypes.APP_FETCH_HOME_PAGE,
        payload: { homePage: response.data },
      });
    } catch (error) {
      console.error(error);
      if (error.response?.data?.message)
        notification.error({
          message: error.response?.data?.message,
          duration: 3,
        });
      return false;
    }
  };

// Server side - disclaimers
export const fetchServerDisclaimers = async (
  accessToken: string,
  ctx: NextPageContext
) => {
  try {
    const disclaimers: IDisclaimerDTO[] = await getDisclaimers(accessToken);

    ctx.store.dispatch({
      type: appTypes.APP_FETCH_DISCLAIMERS,
      payload: {
        private: mapLegacyURLs(disclaimers),
      },
    });
  } catch (err) {
    console.error(err);
    if (err.response?.data?.message)
      notification.error({
        message: err.response?.data?.message,
        duration: 3,
      });
    return false;
  }
};

export const fetchPublicDisclaimers = async (ctx: NextPageContext) => {
  const reduxDisclaimers = (ctx.store.getState() as IRootReducers).app
    .disclaimers.public;

  if (reduxDisclaimers?.length > 0) return;

  try {
    const disclaimers: IDisclaimerDTO[] = await getPublicDisclaimers();

    ctx.store.dispatch({
      type: appTypes.APP_FETCH_PUBLIC_DISCLAIMERS,
      payload: { public: mapLegacyURLs(disclaimers) },
    });
  } catch (err) {
    console.error(err);
    if (err.response?.data?.message)
      notification.error({
        message: err.response?.data?.message,
        duration: 3,
      });
    return false;
  }
};

export const fetchMaintenanceModesSSR = async (
  ctx: NextPageContext,
  force = false
) => {
  const _maintenanceModes = (ctx.store.getState() as IRootReducers).app
    .maintenanceModes;

  if (_maintenanceModes !== null && !force) return _maintenanceModes;

  try {
    const maintenanceModes: IMaintenanceMode[] = await getMaintenanceModes();

    ctx.store.dispatch({
      type: appTypes.APP_FETCH_MAINTENANCE_MODE,
      payload: {
        maintenanceMode: maintenanceModes,
      },
    });
    return maintenanceModes;
  } catch (err) {
    console.error(err);
    if (err.response?.data?.message)
      notification.error({
        message: err.response?.data?.message,
        duration: 3,
      });
    return [];
  }
};

export const fetchMaintenanceModes =
  (force = false) =>
  async (
    dispatch: Dispatch<IFetchMaintenanceMode>,
    getState: () => IRootReducers
  ) => {
    const _maintenanceModes = (getState() as IRootReducers).app
      .maintenanceModes;

    if (_maintenanceModes !== null && !force) return _maintenanceModes;

    try {
      const maintenanceModes: IMaintenanceMode[] = await getMaintenanceModes();

      dispatch({
        type: appTypes.APP_FETCH_MAINTENANCE_MODE,
        payload: {
          maintenanceMode: maintenanceModes,
        },
      });
      return maintenanceModes;
    } catch (err) {
      console.error(err);
      if (err.response?.data?.message)
        notification.error({
          message: err.response?.data?.message,
          duration: 3,
        });
      return [];
    }
  };

export const fetchDisclaimers =
  (accessToken: string) =>
  async (
    dispatch: Dispatch<IFetchDisclaimers>,
    getState: () => IRootReducers
  ) => {
    try {
      const [disclaimers, commercialDisclaimers] = await Promise.all([
        getDisclaimers(accessToken),
        getCommercialDisclaimers(accessToken),
      ]);

      if (getState().auth.accessToken) {
        dispatch({
          type: appTypes.APP_FETCH_DISCLAIMERS,
          payload: {
            private: mapLegacyURLs(disclaimers),
            commercial: commercialDisclaimers,
          },
        });
      }
    } catch (err) {
      console.error(err);
      if (err.response?.data?.message)
        notification.error({
          message: err.response?.data?.message,
          duration: 3,
        });
      return false;
    }
  };

export const getDisclaimers = async (
  accessToken: string
): Promise<IDisclaimerDTO[]> => {
  const response = await fetch(`${BASE_URL}${apiPaths.DISCLAIMERS}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return response.ok ? await response.json() : [];
};

export const getCommercialDisclaimers = async (accessToken: string) => {
  const response = await fetch(
    `${BASE_URL}${apiPaths.COMMERCIAL_DISCLAIMERS}`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  return response.ok ? await response.json() : [];
};

export const getPublicDisclaimers = async (): Promise<IDisclaimerDTO[]> => {
  const response = await fetch(`${BASE_URL}${apiPaths.DISCLAIMERS_PUBLIC}`, {
    method: 'GET',
  });

  return response.ok ? await response.json() : [];
};

export const getMaintenanceModes = async (): Promise<IMaintenanceMode[]> => {
  const response = await fetch(`${BASE_URL}${apiPaths.MAINTENANCE_MODE}`, {
    method: 'GET',
  });

  return response.ok ? await response.json() : [];
};

export const updateChallenges =
  (payload: {
    challengeType: CHALLENGE_TYPE_VALUES;
    challengeList: {
      total: number;
      challenges: ChallengeDetail[];
    };
  }) =>
  async (dispatch: Dispatch<IUpdateChallenges>) => {
    try {
      dispatch({
        type: appTypes.APP_UPDATE_CHALLENGES,
        payload,
      });
    } catch (err) {
      console.error(err);
    }
  };

export const insertAnnualCampaign =
  (payload: ChallengeDetail) =>
  async (dispatch: Dispatch<IInsertAnnualCampaign>) => {
    try {
      dispatch({
        type: appTypes.APP_INSERT_ANNUAL_CAMPAIGN,
        payload: {
          challenge: payload,
        },
      });
    } catch (err) {
      console.error(err);
    }
  };

export const insertChallenges =
  (payload: {
    challengeKey: CHALLENGE_TYPE_VALUES;
    challengeList: IChallengesList;
  }) =>
  async (dispatch: Dispatch<IInsertChallenges>) => {
    try {
      dispatch({
        type: appTypes.APP_INSERT_CHALLENGES,
        payload: {
          challengeKey: payload.challengeKey,
          challengeList: payload.challengeList,
        },
      });
    } catch (err) {
      console.error(err);
    }
  };

export const setChallenge =
  (challenge: ChallengeDetail) => async (dispatch: Dispatch<ISetChallenge>) => {
    try {
      dispatch({
        type: appTypes.APP_SET_CHALLENGE,
        payload: { challenge },
      });
    } catch (err) {
      console.error(err);
    }
  };

export const setStaticPage =
  (staticPage: Partial<IContentPage>) =>
  async (dispatch: Dispatch<ISetStaticPage>) => {
    try {
      dispatch({
        type: appTypes.APP_SET_STATIC_PAGE,
        payload: {
          staticPageConfig: {
            showFooter: staticPage?.footer,
            showHeader: staticPage?.header,
          },
        },
      });
    } catch (err) {
      console.error(err);
    }
  };

// Server side - user menu
export const fetchServerUserMenu = async (
  accessToken: string,
  ctx: NextPageContext
) => {
  const userMenu = await getUserMenu(accessToken);

  if (userMenu) {
    ctx.store.dispatch({
      type: appTypes.APP_FETCH_USER_MENU,
      payload: { userMenu },
    });
  }
};

// Client side - user menu
export const fetchClientUserMenu =
  (accessToken: string) =>
  async (dispatch: Dispatch<ISetUserMenu>, getState: () => IRootReducers) => {
    const userMenu = await getUserMenu(accessToken);
    if (userMenu && getState().auth.accessToken)
      dispatch({
        type: appTypes.APP_FETCH_USER_MENU,
        payload: { userMenu },
      });
  };

export const getUserMenu = async (accessToken: string) => {
  const response = await fetch(`${BASE_URL}${apiPaths.CALL.USER_MENU}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  const userMenu: IMenu[] = response.ok ? await response.json() : null;

  return userMenu?.map((menu) => {
    return {
      ...menu,
      subMenu: menu.subMenu.map((submenu) => {
        return {
          ...submenu,
          hrefAs: submenu?.route,
          href: isRouteDefined(submenu.route)
            ? submenu.route
            : `/static?page=${submenu.route}`,
        };
      }),
    };
  });
};

export const resetUserMenu = () => (dispatch: Dispatch<ISetUserMenu>) => {
  dispatch({
    type: appTypes.APP_FETCH_USER_MENU,
    payload: { userMenu: null },
  });
};

export const resetUserHome = () => (dispatch: Dispatch<ISetUserHome>) => {
  dispatch({
    type: appTypes.APP_FETCH_USER_HOME,
    payload: { userHome: null },
  });
};

export const setSalesforceCenter =
  (salesforceCenter: ISalesforceCenter) =>
  (dispatch: Dispatch<ISetSalesforceCenter>) => {
    dispatch({
      type: appTypes.APP_SET_SALESFORCE_CENTER,
      payload: { salesforceCenter },
    });
  };

export const getRedeemPointMagentoCart =
  () => async (dispatch: Dispatch<IFetchCart>) => {
    try {
      const response = await api.getDataCall({
        dataPath: apiPaths.MAGENTO.CART,
        callConfig: {},
      });

      const cart = response.data || { items: [] };

      dispatch({
        type: appTypes.APP_FETCH_CART,
        payload: { cart },
      });

      return cart;
    } catch (err) {
      if (err.response?.data?.message) console.error(err);
      console.error(err);
      if (err?.response?.data?.message)
        notification.error({
          duration: 3,
          message: err.response?.data.message,
        });
    }
  };

export const showBagMenu =
  (value: boolean) => async (dispatch: Dispatch<IShowBagMenu>) => {
    try {
      dispatch({
        type: appTypes.APP_SHOW_BAG_MENU,
        payload: { showSiderMenu: value },
      });

      return;
    } catch (err) {
      console.error(err);
    }
  };

export const addBagProduct =
  (product: IRedeemPointsProduct, cart: IRedeemPointsCart) =>
  async (dispatch: Dispatch<IAddBagProduct>) => {
    try {
      const response = await api.postDataCall({
        dataPath: apiPaths.MAGENTO.CART_ITEM,
        data: {
          sku: product.sku,
          qty: product.qty,
          quote_id: cart.id,
        },
        callConfig: {},
      });

      const updatedCart = response.data || { items: [] };

      dispatch({
        type: appTypes.APP_ADD_BAG_PRODUCT,
        payload: { cart: updatedCart },
      });
      return updatedCart;
    } catch (err) {
      console.error(err);
      if (err.response.data.code === '900')
        return {
          error: err,
          success: false,
        };
      if (
        err.response?.data?.code === '0018' &&
        err.response?.data?.message?.includes('maximum qty allowed')
      )
        return err;
      if (err.response?.data?.message) {
        notification.error({
          message: err.response?.data?.message,
          duration: 3,
        });
        return {
          success: false,
        };
      }
    }
  };

export const updateBagProduct =
  (product: IRedeemPointsProduct, cart: IRedeemPointsCart) =>
  async (dispatch: Dispatch<IUpdateBagProduct>) => {
    try {
      const response = await api.putDataCallById({
        dataPath: apiPaths.MAGENTO.CART_ITEM,
        id: product.item_id,
        data: {
          sku: product.sku,
          qty: product.qty,
          quote_id: cart.id,
        },
      });

      const updatedCart = response.data || { items: [] };

      dispatch({
        type: appTypes.APP_UPDATE_BAG_PRODUCT,
        payload: { cart: updatedCart },
      });
      return updatedCart;
    } catch (err) {
      console.error(err);
      if (err.response?.data?.code !== '0018')
        notification.error({
          message: err.response?.data?.message,
          duration: 3,
        });
    }
  };

export const removeBagProduct =
  (product: IRedeemPointsProduct, cart: IRedeemPointsCart, updateCard = true) =>
  async (dispatch: Dispatch<IRemoveBagProduct>) => {
    try {
      const response = await api.deleteDataCallNoID({
        dataPath: apiPaths.MAGENTO.CART_ITEM,
        callConfig: {
          data: {
            item_id: product.item_id,
            quote_id: cart.id,
          },
        },
      });

      const updatedCart = response.data || { items: [] };

      if (updateCard)
        dispatch({
          type: appTypes.APP_REMOVE_BAG_PRODUCT,
          payload: { cart: updatedCart },
        });

      return getRedeemPointMagentoCart();
    } catch (err) {
      console.error(err);
      if (err.response?.data?.message)
        notification.error({
          message: err.response?.data?.message,
          duration: 3,
        });
    }
  };

export const addDiscountCoupon =
  (couponCode: string, cartId: number) =>
  async (dispatch: Dispatch<IAddOrRemoveDiscountCoupon>) => {
    try {
      const response = await api.putDataCall({
        dataPath: apiPaths.MAGENTO.DISCOUNT_CUPON,
        data: {
          couponCode,
          cartId,
        },
        callConfig: {},
      });

      const couponInfo = response.data;

      if (couponInfo)
        dispatch({
          type: appTypes.APP_ADD_OR_REMOVE_DISCOUNT_COUPON,
          payload: {
            couponData: couponInfo.couponData,
            cartTotals: couponInfo.cartTotals,
          },
        });

      return couponInfo;
    } catch (err) {
      console.error(err);
      if (err.response?.data?.message)
        notification.error({
          message: err.response?.data?.message,
          duration: 3,
        });
    }
  };

export const deleteDiscountCoupon =
  (couponCode: string, cartId: number) =>
  async (dispatch: Dispatch<IAddOrRemoveDiscountCoupon>) => {
    try {
      const response = await api.deleteDataCallNoID({
        dataPath: apiPaths.MAGENTO.DISCOUNT_CUPON,
        callConfig: {
          data: {
            couponCode,
            cartId,
          },
        },
      });

      const couponInfo = response.data;

      if (couponInfo)
        dispatch({
          type: appTypes.APP_ADD_OR_REMOVE_DISCOUNT_COUPON,
          payload: {
            couponData: couponInfo.couponData,
            cartTotals: couponInfo.cartTotals,
          },
        });

      return couponInfo;
    } catch (err) {
      console.error(err);
      if (err.response?.data?.message)
        notification.error({
          message: err.response?.data?.message,
          duration: 3,
        });
    }
  };

export const setProvinces =
  (provinces: IRegion[]) => async (dispatch: Dispatch<IProvinceCountries>) => {
    try {
      dispatch({
        type: appTypes.APP_SET_PROVINCES_COUNTRIES,
        payload: { provinces },
      });
      return;
    } catch (err) {
      console.error(err);
    }
  };

export const setCountry =
  (country: string) => async (dispatch: Dispatch<ICountry>) => {
    try {
      dispatch({
        type: appTypes.APP_SET_COUNTRY,
        payload: { country },
      });
      return;
    } catch (err) {
      console.error(err);
    }
  };
