import { IResourceList } from 'src/components/challenge/ChallengeInterfaces';
import { ResourceTypeDetailEnum } from 'src/shared/enums';
import { IChallengeVideo, isVideoValidFormat } from 'src/shared/models';

const {
  VIDEO_IS_VERTICAL,
  VIDEO_DESCRIPTION,
  VIDEO_OPTIONAL_IMAGE,
  VIDEO_URL,
} = ResourceTypeDetailEnum;

export const buildChallengeVideo = (
  resource: IResourceList
): IChallengeVideo => {
  const { resourceDetailList, score, idResource, name: title } = resource;
  let item: IChallengeVideo;

  resourceDetailList
    .filter(({ status }) => status)
    .forEach((_item) => {
      switch (_item.idResourceTypeD.idResourceTypeD) {
        case VIDEO_IS_VERTICAL:
          item = {
            ...item,
            videoIsVertical: _item.value === 'true',
          };
          break;
        case VIDEO_DESCRIPTION:
          item = {
            ...item,
            description: _item.value,
            isImage: false,
          };
          break;

        case VIDEO_OPTIONAL_IMAGE:
          item = {
            ...item,
            image: _item.value,
            isImage: true,
          };
          break;
        case VIDEO_URL:
          item = {
            ...item,
            url: _item.value,
            isVideoFile: isVideoValidFormat(_item.value || ''),
          };
          break;
      }
    });

  return {
    ...item,
    idResource,
    title,
    points: score?.points || 0,
    icon: 'campaign-video',
  };
};
