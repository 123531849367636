import { YuBanner } from '@isdin/yuma-react-web-pin';
import { useIntl } from 'react-intl';
import { IRootReducers } from '../../reducers';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { privateRoute } from '../../../services/privateRoute';
import React from 'react';
import { useTotalItemsToRemoveBag } from '../../hooks/useTotalItemsToRemoveBag';

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const _BannerMaximumWarningExceeded = ({
  bagProducts,
  orderRestrictions,
}: Props): JSX.Element => {
  const { formatMessage } = useIntl();

  const toRemove = useTotalItemsToRemoveBag({ orderRestrictions, bagProducts });

  if (toRemove <= 0) return null;

  return (
    <YuBanner icon status="warning">
      {formatMessage(
        {
          id:
            toRemove === 1
              ? 'components.banner-maximum-warning-exceeded.singular'
              : 'components.banner-maximum-warning-exceeded.plural',
        },
        {
          remove: toRemove,
          max: orderRestrictions.salesRestrictions.maxSaleRestrictions.annual
            .maxTotalProductsByPeriod,
          b: (chunks: string) => <b>{chunks}</b>,
        }
      )}
    </YuBanner>
  );
};

const mapStateToProps = (state: IRootReducers) => {
  return {
    orderRestrictions: state.auth.user.magento?.orderRestrictions,
    bagProducts: state.app.redeemPoints.cart.items,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({}, dispatch);

export const BannerMaximumWarningExceeded = connect(
  mapStateToProps,
  mapDispatchToProps
)(
  privateRoute({
    WrappedComponent: _BannerMaximumWarningExceeded,
  })
);
