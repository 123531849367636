import { ChannelType } from 'src/shared/enums/platform.enum';

export const PIN_US = [
  {
    region: 'Alabama',
    region_code: 'AL',
    region_id: 1,
    channel: ChannelType.NATIONAL,
  },
  {
    region: 'Alaska',
    region_code: 'AK',
    region_id: 2,
    channel: ChannelType.NATIONAL,
  },
  {
    region: 'American Samoa',
    region_code: 'AS',
    region_id: 3,
    channel: ChannelType.NATIONAL,
  },
  {
    region: 'Arizona',
    region_code: 'AZ',
    region_id: 4,
    channel: ChannelType.NATIONAL,
  },
  {
    region: 'Arkansas',
    region_code: 'AR',
    region_id: 5,
    channel: ChannelType.NATIONAL,
  },
  {
    region: 'Armed Forces Africa',
    region_code: 'AE',
    region_id: 6,
    channel: ChannelType.NATIONAL,
  },
  {
    region: 'Armed Forces Americas',
    region_code: 'AA',
    region_id: 7,
    channel: ChannelType.NATIONAL,
  },
  {
    region: 'Armed Forces Canada',
    region_code: 'AE',
    region_id: 8,
    channel: ChannelType.NATIONAL,
  },
  {
    region: 'Armed Forces Europe',
    region_code: 'AE',
    region_id: 9,
    channel: ChannelType.NATIONAL,
  },
  {
    region: 'Armed Forces Middle East',
    region_code: 'AE',
    region_id: 10,
    channel: ChannelType.NATIONAL,
  },
  {
    region: 'Armed Forces Pacific',
    region_code: 'AP',
    region_id: 11,
    channel: ChannelType.NATIONAL,
  },
  {
    region: 'California',
    region_code: 'CA',
    region_id: 12,
    channel: ChannelType.NATIONAL,
  },
  {
    region: 'Colorado',
    region_code: 'CO',
    region_id: 13,
    channel: ChannelType.NATIONAL,
  },
  {
    region: 'Connecticut',
    region_code: 'CT',
    region_id: 14,
    channel: ChannelType.NATIONAL,
  },
  {
    region: 'Delaware',
    region_code: 'DE',
    region_id: 15,
    channel: ChannelType.NATIONAL,
  },
  {
    region: 'District of Columbia',
    region_code: 'DC',
    region_id: 16,
    channel: ChannelType.NATIONAL,
  },
  {
    region: 'Federated States Of Micronesia',
    region_code: 'FM',
    region_id: 17,
    channel: ChannelType.NATIONAL,
  },
  {
    region: 'Florida',
    region_code: 'FL',
    region_id: 18,
    channel: ChannelType.NATIONAL,
  },
  {
    region: 'Georgia',
    region_code: 'GA',
    region_id: 19,
    channel: ChannelType.NATIONAL,
  },
  {
    region: 'Guam',
    region_code: 'GU',
    region_id: 20,
    channel: ChannelType.NATIONAL,
  },
  {
    region: 'Hawaii',
    region_code: 'HI',
    region_id: 21,
    channel: ChannelType.NATIONAL,
  },
  {
    region: 'Idaho',
    region_code: 'ID',
    region_id: 22,
    channel: ChannelType.NATIONAL,
  },
  {
    region: 'Illinois',
    region_code: 'IL',
    region_id: 23,
    channel: ChannelType.NATIONAL,
  },
  {
    region: 'Indiana',
    region_code: 'IN',
    region_id: 24,
    channel: ChannelType.NATIONAL,
  },
  {
    region: 'Iowa',
    region_code: 'IA',
    region_id: 25,
    channel: ChannelType.NATIONAL,
  },
  {
    region: 'Kansas',
    region_code: 'KS',
    region_id: 26,
    channel: ChannelType.NATIONAL,
  },
  {
    region: 'Kentucky',
    region_code: 'KY',
    region_id: 27,
    channel: ChannelType.NATIONAL,
  },
  {
    region: 'Louisiana',
    region_code: 'LA',
    region_id: 28,
    channel: ChannelType.NATIONAL,
  },
  {
    region: 'Maine',
    region_code: 'ME',
    region_id: 29,
    channel: ChannelType.NATIONAL,
  },
  {
    region: 'Marshall Islands',
    region_code: 'MH',
    region_id: 30,
    channel: ChannelType.NATIONAL,
  },
  {
    region: 'Maryland',
    region_code: 'MD',
    region_id: 31,
    channel: ChannelType.NATIONAL,
  },
  {
    region: 'Massachusetts',
    region_code: 'MA',
    region_id: 32,
    channel: ChannelType.NATIONAL,
  },
  {
    region: 'Michigan',
    region_code: 'MI',
    region_id: 33,
    channel: ChannelType.NATIONAL,
  },
  {
    region: 'Minnesota',
    region_code: 'MN',
    region_id: 34,
    channel: ChannelType.NATIONAL,
  },
  {
    region: 'Mississippi',
    region_code: 'MS',
    region_id: 35,
    channel: ChannelType.NATIONAL,
  },
  {
    region: 'Missouri',
    region_code: 'MO',
    region_id: 36,
    channel: ChannelType.NATIONAL,
  },
  {
    region: 'Montana',
    region_code: 'MT',
    region_id: 37,
    channel: ChannelType.NATIONAL,
  },
  {
    region: 'Nebraska',
    region_code: 'NE',
    region_id: 38,
    channel: ChannelType.NATIONAL,
  },
  {
    region: 'Nevada',
    region_code: 'NV',
    region_id: 39,
    channel: ChannelType.NATIONAL,
  },
  {
    region: 'New Hampshire',
    region_code: 'NH',
    region_id: 40,
    channel: ChannelType.NATIONAL,
  },
  {
    region: 'New Jersey',
    region_code: 'NJ',
    region_id: 41,
    channel: ChannelType.NATIONAL,
  },
  {
    region: 'New Mexico',
    region_code: 'NM',
    region_id: 42,
    channel: ChannelType.NATIONAL,
  },
  {
    region: 'New York',
    region_code: 'NY',
    region_id: 43,
    channel: ChannelType.NATIONAL,
  },
  {
    region: 'North Carolina',
    region_code: 'NC',
    region_id: 44,
    channel: ChannelType.NATIONAL,
  },
  {
    region: 'North Dakota',
    region_code: 'ND',
    region_id: 45,
    channel: ChannelType.NATIONAL,
  },
  {
    region: 'Northern Mariana Islands',
    region_code: 'MP',
    region_id: 46,
    channel: ChannelType.NATIONAL,
  },
  {
    region: 'Ohio',
    region_code: 'OH',
    region_id: 47,
    channel: ChannelType.NATIONAL,
  },
  {
    region: 'Oklahoma',
    region_code: 'OK',
    region_id: 48,
    channel: ChannelType.NATIONAL,
  },
  {
    region: 'Oregon',
    region_code: 'OR',
    region_id: 49,
    channel: ChannelType.NATIONAL,
  },
  {
    region: 'Palau',
    region_code: 'PW',
    region_id: 50,
    channel: ChannelType.NATIONAL,
  },
  {
    region: 'Pennsylvania',
    region_code: 'PA',
    region_id: 51,
    channel: ChannelType.NATIONAL,
  },
  {
    region: 'Puerto Rico',
    region_code: 'PR',
    region_id: 52,
    channel: ChannelType.NATIONAL,
  },
  {
    region: 'Rhode Island',
    region_code: 'RI',
    region_id: 53,
    channel: ChannelType.NATIONAL,
  },
  {
    region: 'South Carolina',
    region_code: 'SC',
    region_id: 54,
    channel: ChannelType.NATIONAL,
  },
  {
    region: 'South Dakota',
    region_code: 'SD',
    region_id: 55,
    channel: ChannelType.NATIONAL,
  },
  {
    region: 'Tennessee',
    region_code: 'TN',
    region_id: 56,
    channel: ChannelType.NATIONAL,
  },
  {
    region: 'Texas',
    region_code: 'TX',
    region_id: 57,
    channel: ChannelType.NATIONAL,
  },
  {
    region: 'Utah',
    region_code: 'UT',
    region_id: 58,
    channel: ChannelType.NATIONAL,
  },
  {
    region: 'Vermont',
    region_code: 'VT',
    region_id: 59,
    channel: ChannelType.NATIONAL,
  },
  {
    region: 'Virgin Islands',
    region_code: 'VI',
    region_id: 60,
    channel: ChannelType.NATIONAL,
  },
  {
    region: 'Virginia',
    region_code: 'VA',
    region_id: 61,
    channel: ChannelType.NATIONAL,
  },
  {
    region: 'Washington',
    region_code: 'WA',
    region_id: 62,
    channel: ChannelType.NATIONAL,
  },
  {
    region: 'West Virginia',
    region_code: 'WV',
    region_id: 63,
    channel: ChannelType.NATIONAL,
  },
  {
    region: 'Wisconsin',
    region_code: 'WI',
    region_id: 64,
    channel: ChannelType.NATIONAL,
  },
  {
    region: 'Wyoming',
    region_code: 'WY',
    region_id: 65,
    channel: ChannelType.NATIONAL,
  },
].sort(({ region: nameA }, { region: nameB }) => {
  return nameA.localeCompare(nameB, 'en-US');
});
