import { ChannelType } from 'src/shared/enums/platform.enum';
import { IRegion } from 'src/shared/models';

export const PIN_AR: IRegion[] = [
  {
    id: 'a0C5800000FiEQKEA3',
    code: '00',
    name: 'Capital Federal',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEPiEAN',
    code: '01',
    name: 'Buenos Aires',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEQZEA3',
    code: '02',
    name: 'Catamarca',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiERiEAN',
    code: '03',
    name: 'Córdoba',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiERoEAN',
    code: '04',
    name: 'Corrientes',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiETNEA3',
    code: '05',
    name: 'Entre Rios',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEX8EAN',
    code: '06',
    name: 'Jujuy',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEasEAF',
    code: '07',
    name: 'Mendoza',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEYdEAN',
    code: '08',
    name: 'La Rioja',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEgeEAF',
    code: '09',
    name: 'Salta',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEgqEAF',
    code: '10',
    name: 'San Juan',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEgsEAF',
    code: '11',
    name: 'San Luis',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEh1EAF',
    code: '12',
    name: 'Santa Fé',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEh6EAF',
    code: '13',
    name: 'Santiago del Estero',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEk8EAF',
    code: '14',
    name: 'Tucumán',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEQqEAN',
    code: '16',
    name: 'Chaco',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiERHEA3',
    code: '17',
    name: 'Chubut',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEU3EAN',
    code: '18',
    name: 'Formosa',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEbFEAV',
    code: '19',
    name: 'Misiones',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEcCEAV',
    code: '20',
    name: 'Neuquen',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEYbEAN',
    code: '21',
    name: 'La Pampa',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEg6EAF',
    code: '22',
    name: 'Rio Negro',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEgzEAF',
    code: '23',
    name: 'Santa Cruz',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEjfEAF',
    code: '24',
    name: 'Tierra del Fuego',
    channel: ChannelType.NATIONAL,
  },
].sort(({ name: nameA }, { name: nameB }) => {
  return nameA.localeCompare(nameB, 'es-ES');
});
