import { ChannelType } from 'src/shared/enums/platform.enum';
import { IRegion } from 'src/shared/models';

export const PIN_PE: IRegion[] = [
  {
    id: 'a0C5800000FiEMIEA3',
    code: '11',
    name: 'Amazonas',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEMMEA3',
    code: '5',
    name: 'Ancash',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEMeEAN',
    code: '19',
    name: 'Apurimac',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiENXEA3',
    code: '8',
    name: 'Arequipa',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEOBEA3',
    code: '18',
    name: 'Ayacucho',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEQ0EAN',
    code: '12',
    name: 'Cajamarca',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEQ4EAN',
    code: '25',
    name: 'Callao',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiES8EAN',
    code: '20',
    name: 'Cuzco',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEVtEAN',
    code: '17',
    name: 'Huancavelica',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEVuEAN',
    code: '14',
    name: 'Huánuco',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEW6EAN',
    code: '7',
    name: 'Ica',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEX9EAN',
    code: '16',
    name: 'Junín',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEYYEA3',
    code: '4',
    name: 'La Libertad',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEYmEAN',
    code: '3',
    name: 'Lambayeque',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEZJEA3',
    code: '6',
    name: 'Lima',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEZlEAN',
    code: '22',
    name: 'Loreto',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEa6EAF',
    code: '24',
    name: 'Madre de Dios',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEbaEAF',
    code: '9',
    name: 'Moquegua',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEdrEAF',
    code: '15',
    name: 'Pasco',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEeZEAV',
    code: '2',
    name: 'Piura',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEf3EAF',
    code: '21',
    name: 'Puno',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEguEAF',
    code: '13',
    name: 'San Martín',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEivEAF',
    code: '10',
    name: 'Tacna',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEkAEAV',
    code: '1',
    name: 'Tumbes',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEkHEAV',
    code: '23',
    name: 'Ucayali',
    channel: ChannelType.NATIONAL,
  },
].sort(({ name: nameA }, { name: nameB }) => {
  return nameA.localeCompare(nameB, 'es-ES');
});
